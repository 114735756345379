import { SupabaseService } from "./service";
import { AppResponse } from "../types/request";
import { getNewAvatarPath } from "../utils/profile";

interface UploadParams {
  name: string;
  path?: string;
  blob?: Blob;
  file?: File;
  contentType: string;

  progressCallback?: (progress: { loaded: number; total: number }) => void;
}

export default class StorageService extends SupabaseService {
  // upload: Supports file uploads for capsule videos and profile pictures
  upload = async (params: UploadParams) => {
    try {
      const presignedUrl = await this.getUpdateSignedUrl(params.name);

      if (presignedUrl.error) {
        return { data: null, error: presignedUrl.error };
      }

      const response = await fetch(presignedUrl.data.signedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": params.contentType,
        },
        body: (params.file || params.blob) as File | Blob,
      });

      if (!response.ok) {
        return { data: null, error: new Error("Failed to upload") };
      }

      return { data: { success: true }, error: null };
    } catch (error) {
      return { error, data: null };
    }
  };

  getUpdateSignedUrl = async (
    key: string,
  ): AppResponse<{ signedUrl: string }> => {
    try {
      const { data, error } = await this.supabase.functions.invoke(
        "get-s3-presigned-url",
        {
          body: { key, command: "put" },
        },
      );

      if (error) {
        return { error, data: null };
      }

      return { data, error: null };
    } catch (error) {
      return { error: error as Error, data: null };
    }
  };

  getReadSignedUrl = async (
    key: string,
  ): AppResponse<{ signedUrl: string }> => {
    try {
      const { data, error } = await this.supabase.functions.invoke(
        "get-s3-presigned-url",
        {
          body: { key, command: "get" },
        },
      );

      if (error) {
        return { error, data: null };
      }

      return { data, error: null };
    } catch (error) {
      return { error: error as Error, data: null };
    }
  };

  thumbnailUpload = async (params: UploadParams) => {
    if (!params.path) {
      return { data: null, error: new Error("Path is required") };
    } else if (!params.blob) {
      return { data: null, error: new Error("Blob is required") };
    }

    return this.supabase.storage
      .from("thumbnails")
      .upload(params.path, params.blob, { contentType: params.contentType });
  };

  getCapsuleVideoThumbnailSignedUrl = async (
    key: string,
  ): AppResponse<{ signedUrl: string }> => {
    const { data, error } = await this.supabase.storage
      .from("thumbnails")
      .createSignedUrl(key, 60);
    if (error) {
      return { error, data: null };
    }
    return { data, error: null };
  };

  /**
   * Updates the logged in user's avatar by deleting the current avatar and uploading the new one
   * @param avatar The image to use as the user's avatar
   * @param authId The auth ID of the user, will be looked up if not passed in
   * @param oldAvatarPath The path to the user's current avatar, if one exists
   * @returns The new avatar path if updated successfully, null if there was an error
   */
  updateAvatar = async (
    avatar: File,
    authId?: string,
    oldAvatarPath?: string,
  ): Promise<string | null> => {
    try {
      if (!authId) {
        const { data, error } = await this.supabase.auth.getUser();
        if (error) {
          throw new Error(error.message);
        }
        authId = data.user.id;
      }

      if (oldAvatarPath) {
        await this.deleteAvatar(oldAvatarPath);
      }

      const newAvatarPath = getNewAvatarPath(
        authId,
        avatar.type,
        oldAvatarPath,
      );

      await this.uploadAvatar(avatar, newAvatarPath);
      await this.updateAvatarPath(authId, newAvatarPath);

      return newAvatarPath;
    } catch (e) {
      return null;
    }
  };

  /**
   * Deletes the user's current avatar in supabase
   * @param avatarPath The path to the user's current avatar
   */
  private deleteAvatar = async (avatarPath: string) => {
    const { error } = await this.supabase.storage
      .from("avatars")
      .remove([avatarPath]);

    if (error) {
      throw new Error(error.message);
    }
  };

  /**
   * Uploads the user's new avatar to supabase
   * @param avatar The image to use as the user's avatar
   * @param avatarPath The path to upload the image to
   */
  private uploadAvatar = async (avatar: File, avatarPath: string) => {
    const { error } = await this.supabase.storage
      .from("avatars")
      .upload(avatarPath, avatar);

    if (error) {
      throw new Error(error.message);
    }
  };

  /**
   * Gets the URL for the avatar of the user from the database
   * @param avatarPath The path to the user's avatar, from the public.users table
   * @returns A string with the public URL of the avatar
   */
  getAvatarUrl = async (avatarPath: string) => {
    const { data } = this.supabase.storage
      .from("avatars")
      .getPublicUrl(avatarPath);

    return data.publicUrl;
  };

  /**
   * Deletes one or more thumbnail images/gifs from supabase storage
   * @param thumbnailPaths List of thumbnails to be deleted
   * @returns An error if one occurred
   */
  deleteThumbnails = async (
    thumbnailPaths: string[],
  ): Promise<Error | null> => {
    const { error } = await this.supabase.storage
      .from("thumbnails")
      .remove(thumbnailPaths);

    return error;
  };

  /**
   * Deletes one or more attachment files from s3 storage
   * @param keys List of attachment keys to be deleted, in the format amazon expects
   * @returns An error if one occurred
   */
  deleteAttachments = async (
    keys: { Key: string }[],
  ): Promise<Error | null> => {
    const { error } = await this.supabase.functions.invoke(
      "delete-s3-objects",
      {
        body: { keys },
      },
    );

    return error;
  };
}
