import React, { useCallback, useMemo } from "react";
import Divider from "@mui/material/Divider";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import { Home, ExitToApp, AccountCircle } from "@mui/icons-material";
import { useAuth } from "../../Api/hooks/useAuth.tsx";
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
import { withRouter } from "react-router-dom";
import { ListItemButton, styled } from "@mui/material";

const blackListedRoutes = ["/reset-password"];

const MobileMenuRoot = styled("div", {
  name: "MobileMenu",
  slot: "root",
})(({ theme }) => ({
  width: theme.spacing(30),
  marginTop: theme.spacing(8),
}));

export default withRouter(function LeftDrawerOptions({
  location,
  mobileOpen,
  handleDrawerToggle,
  classes,
  container,
}) {
  const { session, logout } = useAuth();

  const displayLeftDrawer = useMemo(() => {
    // Only display the left drawer if the user is logged in
    // and the current route is not blacklisted
    return session && blackListedRoutes.indexOf(location.pathname) === -1;
  }, [location.pathname]);

  const onLogout = useCallback(() => {
    logout();
  }, []);

  if (displayLeftDrawer) {
    const menu = (
      <div>
        <Divider />
        <ListSubheader inset></ListSubheader>
        <ListItemButton to="/">
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemButton>

        <ListSubheader inset></ListSubheader>
        <ListItemButton to="/Profile">
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </ListItemButton>

        <ListSubheader inset></ListSubheader>
        <ListItemButton onClick={onLogout}>
          <ListItemIcon>
            <ExitToApp />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </div>
    );

    return (
      <nav className={classes.drawer} aria-label="Mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <MobileMenuRoot>{menu}</MobileMenuRoot>
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {menu}
          </Drawer>
        </Hidden>
      </nav>
    );
  }

  return null;
});
