import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Capsule } from "../../Api/capsules";
import { useApi } from "../../Api";
import { withRouter } from "react-router-dom";
import {
  Button,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import BackButton from "../BackButton";
import {
  differenceInDaysBetweenDates,
  humanReadableDate,
} from "../../utils/dateFunctions";
import { useContactsList } from "../../contexts/ContactsListContext";
import { CapsuleFormData, UserData } from "../../types/types";
import { getCapsuleAttachmentKey } from "../../utils/capsules";
import DeleteCapsule from "./DeleteCapsule";
import CapsuleForm from "../Shared/Forms/CapsuleForm";
import { Contact } from "../../Api/contacts";
import ButtonWrapper from "../Shared/ButtonWrapper";

interface CapsuleDetailsProps {
  capsule: Capsule;
  history: any;
}

const Container = styled("div")(({ theme }) => ({
  margin: theme.spacing(3),
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const VideoPlayback = styled("div")({
  minWidth: "320px",
  maxWidth: "800px",
  minHeight: "320px",
});

const ContentDetails = styled("div")(({ theme }) => ({
  backgroundColor: "#E9E9E9",
  padding: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    minWidth: "320px",
  },
  [theme.breakpoints.up("sm")]: {
    minWidth: "640px",
  },
  [theme.breakpoints.up("xl")]: {
    minWidth: "800px",
    maxHeight: "600px",
  },
}));

const CapsuleDetailsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
}));

const MessageContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const CapsuleDetails: FC<CapsuleDetailsProps> = (props) => {
  const [selectedCapsule, setSelectedCapsule] = useState<Capsule>();
  const [videoUrl, setVideoUrl] = useState<string>();
  const api = useApi();
  const { contactsList } = useContactsList();
  const [user, setUser] = useState<UserData | undefined>(undefined);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [recipient, setRecipient] = useState<Contact>();

  const isSentCapsule = useMemo(() => {
    if (user && selectedCapsule) {
      if (selectedCapsule.senderId === user.id) {
        return true;
      } else {
        return false;
      }
    }
  }, [user, selectedCapsule]);

  useEffect(() => {
    if (isSentCapsule && !recipient && selectedCapsule && contactsList) {
      const { recipientId } = selectedCapsule.recipients[0];
      const recipientContact = contactsList.find(
        (contact) => contact.contactId === recipientId,
      );
      if (recipientContact) {
        setRecipient(recipientContact);
      }
    }
  }, [isSentCapsule, selectedCapsule, contactsList]);

  const currentPath = props.history.location.pathname;
  const pathParts = currentPath.split("/");
  const capsuleId = pathParts[2];

  const getCapsuleData = useCallback(async () => {
    const fetchData = async () => {
      const { data: capsuleData, error: capsuleError } =
        await api.capsules.getCapsuleById(capsuleId);
      if (capsuleError) {
        throw new Error(capsuleError.details);
      }
      setSelectedCapsule(capsuleData);

      const attachmentKey = getCapsuleAttachmentKey(capsuleData);
      if (!attachmentKey) {
        throw new Error("Null attachment key");
      }

      const { signedUrl, error: videoError } =
        await api.capsules.getCapsuleAttachmentUrl(attachmentKey);
      if (videoError) {
        throw videoError;
      }

      const { user: userData, error: getUserError } = await api.getUser();
      if (getUserError) {
        throw new Error(getUserError.message);
      }
      setUser(userData);
      setVideoUrl(signedUrl);
    };

    try {
      await fetchData();
    } catch (error) {
      // TODO: Report to Sentry
    }
  }, []);

  useEffect(() => {
    getCapsuleData();
  }, [capsuleId]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const extraLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  const handleBack = () => {
    props.history.goBack();
  };

  const differenceInDaysString = () => {
    if (
      !selectedCapsule ||
      !selectedCapsule.createdAt ||
      !selectedCapsule.sendDate
    ) {
      return;
    }
    const difference = differenceInDaysBetweenDates(
      selectedCapsule.createdAt,
      selectedCapsule.sendDate,
    );

    return difference === 1 ? `${difference} day` : `${difference} days`;
  };

  const senderRecipientString = useMemo(() => {
    if (!selectedCapsule || !user) {
      return "";
    }

    var resultString = "";
    const { recipientId } = selectedCapsule.recipients[0];

    if (isSentCapsule) {
      if (!recipient) {
        console.log("Could not find recipient.");
        return "";
      }
      resultString = `Sent To: ${recipient.firstName} ${recipient.lastName}`;
    } else if (recipientId === user.id) {
      // This is a capsule I've received. Get the sender info from the contacts list.
      const sender = contactsList.find(
        (contact) => contact.contactId === selectedCapsule.senderId,
      );
      if (!sender) {
        console.log("Could not find sender.");
        return "";
      }
      resultString = `Received From: ${sender.firstName} ${sender.lastName}`;
    }
    return resultString;
  }, [selectedCapsule, user, recipient, isSentCapsule]);

  const updateSelectedCapsule = (
    formValues: CapsuleFormData,
    recipientId: string,
  ) => {
    if (recipient && selectedCapsule) {
      const { firstName, lastName, email, message, sendDate } = formValues;
      setRecipient({
        ...recipient,
        contactId: recipientId,
        firstName: firstName,
        lastName: lastName,
        user: { email: email },
      });

      setSelectedCapsule({
        ...selectedCapsule,
        message: message,
        sendDate: sendDate.toISOString(),
      });
    }
    setIsEditMode(false);
  };

  return (
    <div>
      <BackButton handleBack={handleBack}></BackButton>
      <Container>
        <Typography
          variant="h5"
          align="center"
          sx={{ marginBottom: theme.spacing(2) }}
        >
          {selectedCapsule?.title}
        </Typography>
        <VideoPlayback>
          {videoUrl && (
            <video
              controls
              width={isMobile ? 320 : extraLargeScreen ? 800 : 640}
              src={videoUrl}
            ></video>
          )}
        </VideoPlayback>
        {selectedCapsule && (
          <ContentDetails>
            {!isEditMode && (
              <>
                {selectedCapsule.createdAt && selectedCapsule.sendDate && (
                  <div>
                    <CapsuleDetailsContainer>
                      <Typography variant="subtitle1">
                        {`Date Created: ${humanReadableDate(
                          selectedCapsule.createdAt,
                        )}`}
                      </Typography>
                      <Typography variant="subtitle1">
                        {`Date Received: ${humanReadableDate(
                          selectedCapsule.sendDate,
                        )}`}
                      </Typography>
                    </CapsuleDetailsContainer>
                    <CapsuleDetailsContainer>
                      <Typography>{senderRecipientString}</Typography>
                      <Typography>
                        {`Days Elapsed: ${differenceInDaysString()}`}
                      </Typography>
                    </CapsuleDetailsContainer>
                  </div>
                )}
                <MessageContainer>
                  <Typography variant="body1">
                    {selectedCapsule.message}
                  </Typography>
                </MessageContainer>
              </>
            )}
            {selectedCapsule.sendStatus === "scheduled" && recipient && (
              <>
                {isEditMode && (
                  <CapsuleForm
                    recipient={recipient}
                    capsule={selectedCapsule}
                    onCancel={() => {
                      setIsEditMode(false);
                    }}
                    afterSubmit={updateSelectedCapsule}
                  />
                )}
                {!isEditMode && (
                  <ButtonWrapper>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        setIsEditMode(true);
                      }}
                    >
                      Edit
                    </Button>
                    <DeleteCapsule capsule={selectedCapsule} />
                  </ButtonWrapper>
                )}
              </>
            )}
          </ContentDetails>
        )}
      </Container>
    </div>
  );
};

export default withRouter(CapsuleDetails);
