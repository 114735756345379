import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Event } from "../Tracking/Tracking";

const PREFIX = "Updates";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  heading: `${PREFIX}-heading`,
  secondaryHeading: `${PREFIX}-secondaryHeading`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
  expDetails: `${PREFIX}-expDetails`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: "100%",
    TextAlign: "left",
  },

  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },

  [`& .${classes.header}`]: {
    paddingLeft: "2vw",
    color: "#c9c9c9",
    fontSize: "2.5vw",
  },

  [`& .${classes.content}`]: {
    padding: "20px",
  },

  [`& .${classes.heading}`]: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },

  [`& .${classes.secondaryHeading}`]: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  [`& .${classes.buttons}`]: {
    display: "flex",
    justifyContent: "flex-end",
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.expDetails}`]: {
    backgroundColor: "#c9c9c9",
  },
}));

//TODO typography header can be its own class that takes props

class Updates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: null,
      open: true,
    };
  }

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };
  handleClick() {
    Event("Button", "Dev twitter", "Support");
    window.open("https://twitter.com/vtcdevs", "_blank");
  }

  render() {
    const {} = this.props;

    return (
      <Root className="home-container">
        <div className="row aln-center">
          <div className="col-12-wide col-10-normal ">
            <Paper className={classes.paper}>
              <List className={classes.root}>
                <div className={classes.content}>
                  <Typography component="h1" variant="h4" align="center">
                    <b>What's new?</b>
                  </Typography>
                  <Typography component="h1" variant="h6" align="left">
                    <b>07/21/2019</b>
                  </Typography>
                  <Typography component="h1" variant="h5" align="left">
                    <p>
                      <b>Minor UI + navigation changes</b>
                    </p>
                  </Typography>
                  <Typography variant="subtitle1">
                    <ul>
                      <li>
                        We swapped aroung a few of the icons in the navigation
                        bar
                      </li>
                      <li>
                        Dashbord is now your home and landing page is now an
                        about us/welcome page
                      </li>
                      <li>Moved the news/updates tab to the landing page</li>
                    </ul>
                  </Typography>
                </div>
              </List>
            </Paper>
            <Paper className={classes.paper}>
              <List className={classes.root}>
                <div className={classes.content}>
                  <Typography component="h1" variant="h6" align="left">
                    <b>06/09/2019</b>
                  </Typography>
                  <Typography component="h1" variant="h5" align="left">
                    <p>
                      <b>UI updates + bug fixes</b>
                    </p>
                  </Typography>
                  <Typography variant="subtitle1">
                    <ul>
                      <li>
                        The navigation bar has been changed a little to
                        hopefully make things more clear. No pages have changed
                        only the layout of the navigation bar itself.
                      </li>
                      <li>
                        The support page has been reorganized and this update
                        section has been added.
                      </li>
                      <li>
                        Fixed a bug where interrupting a time capsule upload
                        would leave you with an empty time capsule in your sent
                        box. Failed uploads will now be removed properly.
                      </li>
                      <li>
                        The "Change profile image" button on the profile page
                        now lets you change your profile picture.
                      </li>
                    </ul>
                  </Typography>
                </div>
              </List>
            </Paper>

            <Paper className={classes.paper}>
              <List className={classes.root}>
                <div className={classes.content}>
                  <Typography component="h1" variant="h6" align="left">
                    <b>05/27/2019</b>
                  </Typography>
                  <Typography component="h1" variant="h5" align="left">
                    <p>
                      <b>Time capsules can now be sent</b>
                    </p>
                  </Typography>
                  <Typography variant="subtitle1">
                    <ul>
                      <li>
                        Time capsules will now be sent on the date selected.
                      </li>
                      <li>
                        For now email notifications will only go to the primary
                        email you selected for that recipient. We are currently
                        working on alternate set of notifications for the backup
                        contact information.
                      </li>
                    </ul>
                  </Typography>
                </div>
              </List>
            </Paper>
          </div>
        </div>
      </Root>
    );
  }
}

Updates.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Updates;
