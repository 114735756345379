import StepperHeader from "../../TimeCapsuleForm/StepperHeader";
import RecipientForm, {
  CapsuleFormInfo,
} from "../../CreateNewTimeCapsule/RecordNewTimeCapsule/RecipientForm";
import PaymentDetails from "../../CreateNewTimeCapsule/RecordNewTimeCapsule/PaymentDetails";
import React, { useState } from "react";
import { useCreateCapsule } from "../../../contexts/CreateCapsuleContext";
import { styled } from "@mui/material/styles";
import SelectCapsuleVideo from "../../CreateNewTimeCapsule/UploadNewTimeCapsule/SelectCapsuleVideo";
import SendYourCapsule from "../RecordNewTimeCapsule/SendYourCapsule";
import toast from "react-hot-toast";
import { isFileUploadSizeValid } from "../../../utils/file";

enum RecordCapsuleStep {
  UploadVideo = 0,
  RecipientInfo,
  // PaymentDetails,
  SendYourCapsule,
}

const steps = [
  "Upload Video",
  "Recipient Information",
  // "Payment Details",
  "Send Your Capsule",
];

const Root = styled("div")(({ theme }) => ({}));

const UploadNewTimeCapsule = ({ handleCancel, handleSendCapsule }: any) => {
  const [activeStep, setActiveStep] = useState(RecordCapsuleStep.UploadVideo);
  const {
    capsuleFormInfo,
    setCapsuleFormInfo,
    uploadFiles,
    fileUploads,
    thumbnailUploads,
  } = useCreateCapsule();

  function handleSelectCapsuleContinue() {
    if (!capsuleFormInfo.file) {
      toast.error("No file found.");
      return;
    }
    if (capsuleFormInfo.file && isFileUploadSizeValid(capsuleFormInfo.file)) {
      setActiveStep(RecordCapsuleStep.RecipientInfo);
      // Start file upload in the background
      uploadFiles([{ blob: capsuleFormInfo.file }]);
      setCapsuleFormInfo((prevState) => {
        return {
          ...prevState,
          capsule: {
            ...prevState.capsule,
            title: capsuleFormInfo.file?.name as string,
          },
        };
      });
      return;
    }

    toast.error("File size is too large. Please select a smaller file.");
  }

  function handleRecipientInfoBack() {
    setActiveStep(RecordCapsuleStep.UploadVideo);
  }

  function handleRecipientInfoContinue(capsuleFormInfo: CapsuleFormInfo) {
    setActiveStep(RecordCapsuleStep.SendYourCapsule);
    setCapsuleFormInfo((prevState) => {
      return {
        ...prevState,
        ...capsuleFormInfo,
      };
    });
  }

  // function handlePaymentBack() {
  //   setActiveStep(RecordCapsuleStep.RecipientInfo);
  // }

  // function handlePaymentContinue() {
  //   setActiveStep(RecordCapsuleStep.SendYourCapsule);
  // }

  function handleReviewBack() {
    setActiveStep(RecordCapsuleStep.RecipientInfo);
  }

  return (
    <Root>
      <StepperHeader activeStep={activeStep} steps={steps} />
      {activeStep === RecordCapsuleStep.UploadVideo && (
        <SelectCapsuleVideo
          handleBack={handleCancel}
          handleContinue={handleSelectCapsuleContinue}
        />
      )}
      {activeStep === RecordCapsuleStep.RecipientInfo && (
        <RecipientForm
          capsuleFormInfo={capsuleFormInfo}
          setCapsuleFormInfo={setCapsuleFormInfo}
          handleBack={handleRecipientInfoBack}
          handleContinue={handleRecipientInfoContinue}
        />
      )}
      {/* {activeStep === RecordCapsuleStep.PaymentDetails && (
        <PaymentDetails
          handleBack={handlePaymentBack}
          handleContinue={handlePaymentContinue}
        />
      )} */}
      {activeStep === RecordCapsuleStep.SendYourCapsule && (
        <SendYourCapsule
          handleBack={handleReviewBack}
          onSubmit={handleSendCapsule}
          shouldDisableSend={
            capsuleFormInfo.submitStatus === "submitting" ||
            fileUploads[0].status !== "success" ||
            thumbnailUploads[0].status !== "success"
          }
        />
      )}
    </Root>
  );
};

export default UploadNewTimeCapsule;
