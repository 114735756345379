import React, { Component } from "react";
import "../App.css";
import BlogView from "../Components/Blog/BlogView";
import Login from "./Login.js";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.auth == false) {
      return <Login />;
    } else if (this.props.user.length || this.props.userID.length <= 0) {
      return <div>Loading Blog view...</div>;
    } else if (this.props.auth == true) {
      //console.log(this.props.user.attributes)
      return (
        <div className="App">
          <BlogView />
        </div>
      );
    }
  }
}

export default Blog;
