import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Contact } from "../Api/contacts";
import { useApi } from "../Api";

interface ContactsListContextType {
  contactsList: Contact[];
  setContactsList: Dispatch<SetStateAction<Contact[]>>;
}

export const ContactsListContext = createContext<
  ContactsListContextType | undefined
>(undefined);

export const ContactsListProvider = ({ children }) => {
  const [contactsList, setContactsList] = useState<Contact[]>([]);
  const api = useApi();

  const fetchContacts = async () => {
    const { data, error } = await api.contacts.getContacts();
    if (error) {
      return;
    }
    setContactsList(data);
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  return (
    <ContactsListContext.Provider value={{ contactsList, setContactsList }}>
      {children}
    </ContactsListContext.Provider>
  );
};

export function useContactsList(): ContactsListContextType {
  const context = useContext(ContactsListContext);
  if (!context) {
    throw new Error(
      "useContactList must be used within a ContactsListProvider",
    );
  }
  return context;
}
