import React, { useCallback, useEffect, useState } from "react";
import ReactJson from "react-json-view";
import Button from "@mui/material/Button";
import VideoRecorder from "react-video-recorder";
import VideoRecorderDisconnectedView from "../Components/CreateNewTimeCapsule/RecordNewTimeCapsule/VideoRecorder/VideoRecorderDisconnectedView";
import VideoRecorderActions from "../Components/CreateNewTimeCapsule/RecordNewTimeCapsule/VideoRecorder/VideoRecorderActions";
import useFileUpload from "../Api/hooks/useFileUpload";
import { CapsulesListType } from "../Components/Capsules/CapsulesList";
import { useApi } from "../Api";
import SendYourCapsule from "../Components/CreateNewTimeCapsule/RecordNewTimeCapsule/SendYourCapsule";
import {
  Box,
  CircularProgress,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

const Playground = () => {
  const theme = useTheme();
  const [data, setData] = useState<any>(null);
  const api = useApi();
  const { upload, uploads } = useFileUpload("capsule_assets");
  // NOTE: Review how uploads updates as the video is being uploaded
  console.log("uploads: ", uploads);

  const [loadingStatus, setLoadingStatus] = useState<string | null>(
    "uploading",
  );
  const handleStatusChange = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: string | null,
  ) => {
    setLoadingStatus(newStatus);
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await api.contacts.getContacts();
      setData(result);
    };
    fetchData();
  }, []);

  return (
    <div
      style={{ paddingLeft: "12px", paddingRight: "12px", display: "inline" }}
    >
      <h1>Sample Confirmation Screen States</h1>
      <h3>
        Select one of the three buttons below to update the state of the
        component.
      </h3>

      <Box
      // display={"flex"}
      // alignItems={"flex-start"}
      // justifyContent={"space-around"}
      >
        <ToggleButtons
          loadingStatus={loadingStatus}
          handleStatusChange={handleStatusChange}
        ></ToggleButtons>
        <Paper
          sx={{
            marginTop: `${theme.spacing(3)}`,
            marginBottom: `${theme.spacing(3)}`,
            padding: `${theme.spacing(2)}`,
          }}
        >
          {/* <SendYourCapsule
            handleBack={""}
            onSubmit={""}
            shouldDisableSend={""}
            status={loadingStatus}
          ></SendYourCapsule> */}
        </Paper>
        <Box sx={{ width: "100%" }}>
          <h1>Typography Playground</h1>
          <h2>
            These are the names of the different variants of typography and an
            example of what that looks like.
          </h2>
          <Typography variant="h1" gutterBottom>
            h1. Heading
          </Typography>
          <Typography variant="h2" gutterBottom>
            h2. Heading
          </Typography>
          <Typography variant="h3" gutterBottom>
            h3. Heading
          </Typography>
          <Typography variant="h4" gutterBottom>
            h4. Heading
          </Typography>
          <Typography variant="h5" gutterBottom>
            h5. Heading
          </Typography>
          <Typography variant="h6" gutterBottom>
            h6. Heading
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Quos blanditiis tenetur
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Quos blanditiis tenetur
          </Typography>
          <Typography variant="body1" gutterBottom>
            body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore
            consectetur, neque doloribus, cupiditate numquam dignissimos laborum
            fugiat deleniti? Eum quasi quidem quibusdam.
          </Typography>
          <Typography variant="body2" gutterBottom>
            body2. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore
            consectetur, neque doloribus, cupiditate numquam dignissimos laborum
            fugiat deleniti? Eum quasi quidem quibusdam.
          </Typography>
          <Typography variant="button" display="block" gutterBottom>
            button text
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            caption text
          </Typography>
          <Typography variant="overline" display="block" gutterBottom>
            overline text
          </Typography>
        </Box>
      </Box>

      {/*<VideoRecorder*/}
      {/*  onRecordingComplete={(videoBlob) => {*/}
      {/*    console.log("uploading blob: ", videoBlob);*/}
      {/*    upload([{ blob: videoBlob }]);*/}
      {/*  }}*/}
      {/*  timeLimit={120000}*/}
      {/*  isOnInitially="true"*/}
      {/*  renderDisconnectedView={() => <VideoRecorderDisconnectedView />}*/}
      {/*  renderActions={({ ...props }) => (*/}
      {/*    <VideoRecorderActions {...props} prompt={prompt} />*/}
      {/*  )}*/}
      {/*/>*/}
      {/*<Button*/}
      {/*  color="primary"*/}
      {/*  disabled={uploads?.some((u) => u.status !== "success")}*/}
      {/*  onClick={onCreateCapsule}*/}
      {/*>*/}
      {/*  Create capsule*/}
      {/*</Button>*/}

      {/* {data && <ReactJson src={data} />} */}
    </div>
  );
};

const ToggleButtons = ({ loadingStatus, handleStatusChange }) => {
  return (
    <ToggleButtonGroup
      value={loadingStatus}
      exclusive
      onChange={handleStatusChange}
      aria-label="loading status"
    >
      <ToggleButton value="uploading">
        <Box>
          <Typography variant="body1">upload</Typography>
        </Box>
      </ToggleButton>
      <ToggleButton value="success">
        <CheckCircleIcon color="success"></CheckCircleIcon>
        <Typography variant="body1">success</Typography>
      </ToggleButton>
      <ToggleButton value="error">
        <ErrorIcon color="error"></ErrorIcon>
        <Typography variant="body1">error</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default Playground;
