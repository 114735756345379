import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { Event } from "../Tracking/Tracking";
import AboutSending from "./AboutSending";
import AboutBeta from "./AboutBeta";
import DevContact from "./DevContact";
import Updates from "./Updates";

const PREFIX = "SupportTabs";

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
    minHeight: "100vh",
    marginTop: 64,
    backgroundColor: "#fafafa",
    width: "100%",
    padding: 0,
  },
}));

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class SupportTabs extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const {} = this.props;
    const { value } = this.state;

    return (
      <Root className={classes.root}>
        <div className="all-container">
          <div className="row aln-center">
            <div className="col-12-wide col-10-normal ">
              <AppBar position="relative">
                <Tabs
                  variant="fullWidth"
                  value={value}
                  onChange={this.handleChange}
                >
                  <Tab
                    label="Virtual Time Capsules"
                    onClick={() => Event("Button", "Tab Change", "Support")}
                  />
                  <Tab
                    label="Beta"
                    onClick={() => Event("Button", "Tab Change", "Support")}
                  />
                  <Tab
                    label="Updates"
                    onClick={() => Event("Button", "Tab Change", "Support")}
                  />
                </Tabs>
              </AppBar>
            </div>
          </div>
        </div>

        {value === 0 && (
          <TabContainer>
            <AboutSending />
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer>
            <AboutBeta />
            <DevContact />
          </TabContainer>
        )}
        {value === 2 && (
          <TabContainer>
            <Updates />
          </TabContainer>
        )}
      </Root>
    );
  }
}

SupportTabs.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default SupportTabs;
