import React from "react";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import useMediaQuery from "@mui/material/useMediaQuery";

const PREFIX = "StepperHeader";

const classes = {
  stepper: `${PREFIX}-stepper`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.stepper}`]: {
    padding: `${theme.spacing(3)} 0 ${theme.spacing(5)}`,
  },
}));

function StepperHeader(props) {
  const { activeStep, steps } = props;
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Root>
      <Stepper
        activeStep={activeStep}
        className={classes.stepper}
        style={{
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "flex-start" : "center",
        }}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Root>
  );
}

export default StepperHeader;
