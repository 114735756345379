import dayjs from "dayjs";

export const humanReadableDate = (date: string) => {
  return dayjs(date).format("MMMM D, YYYY");
};

export const differenceInDaysBetweenDates = (
  dateOne: string,
  dateTwo: string,
) => {
  return dayjs(dateTwo).diff(dayjs(dateOne), "day");
};
