import {
  Box,
  Button,
  CircularProgress,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useMemo } from "react";
import "../../../css/CreateNewTimeCapsule.css";
import { useCreateCapsule } from "../../../contexts/CreateCapsuleContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { humanReadableDate } from "../../../utils/dateFunctions";

const SendYourCapsule = ({ handleBack, onSubmit, shouldDisableSend }) => {
  const onBack = () => {
    handleBack();
  };

  const sendOrder = () => {
    onSubmit();
  };

  const buttonSpacing = {
    marginTop: (theme) => theme.spacing(3),
    marginLeft: (theme: Theme) => theme.spacing(1),
  };

  const { capsuleFormInfo, fileUploads, thumbnailUploads } = useCreateCapsule();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const status = useMemo(() => {
    if (
      fileUploads[0].status === "error" ||
      thumbnailUploads[0].status === "error"
    ) {
      // TODO: tell log api we got an error throw the error provide error.message error.cause with Sentry
      return "error";
    } else if (
      fileUploads[0].status === "uploading" ||
      // TODO: enhance uploading experience
      thumbnailUploads[0].status === "uploading" ||
      thumbnailUploads[0].status === "generating"
    ) {
      return "uploading";
    } else if (
      fileUploads[0].status === "success" &&
      thumbnailUploads[0].status === "success"
    ) {
      return "success";
    }
  }, [fileUploads, thumbnailUploads]);

  const humanReadableSendDate = humanReadableDate(
    capsuleFormInfo.capsule.sendDate.toString(),
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Typography component="h1" variant="h4" gutterBottom>
        Almost There...
      </Typography>

      <Box
        sx={{ marginBottom: theme.spacing(1) }}
        display={"flex"}
        justifyContent={"space-between"}
        flexDirection={isMobile ? "column" : "row"}
      >
        <Box sx={{ maxWidth: 500, marginBottom: theme.spacing(1) }}>
          <Typography component="h2" variant="h6" gutterBottom>
            Order Summary
          </Typography>
          <Typography variant="subtitle1">
            {capsuleFormInfo.capsule.title}
          </Typography>
        </Box>

        <Box sx={{ maxWidth: 500, marginBottom: theme.spacing(1) }}>
          <Typography component="h2" variant="h6" gutterBottom>
            Time Capsule Details
          </Typography>
          <Typography variant="subtitle1">{`To Be Sent On: ${humanReadableSendDate}`}</Typography>
          {/* <Typography variant="subtitle1">
            File size is only available for Uploaded files. 
            This functionality is not supported for Recorded Videos.
            [File Size]
            `${ fileUploads }`
          </Typography> */}
        </Box>

        <Box sx={{ maxWidth: 500, marginBottom: theme.spacing(1) }}>
          <Typography component="h2" variant="h6" gutterBottom>
            Recipient Details
          </Typography>
          <Typography variant="subtitle1">
            {capsuleFormInfo.recipient.firstName}{" "}
            {capsuleFormInfo.recipient.lastName}
          </Typography>
          <Typography variant="subtitle1">
            {capsuleFormInfo.recipient.email}
          </Typography>
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box>
          {status === "success" && (
            <span>
              <CheckCircleIcon color="success"></CheckCircleIcon>
              Your video is ready to upload!
            </span>
          )}
          {status === "uploading" && (
            <span>
              <CircularProgress></CircularProgress> Capsule upload in
              progress...
            </span>
          )}
          {status === "error" && (
            <span>
              <ErrorIcon color="error"></ErrorIcon> Oh no! Something went wrong
              uploading your capsule.
            </span>
          )}
        </Box>
        <div className="buttonsContainer">
          <Button
            sx={buttonSpacing}
            id="back"
            variant="contained"
            color="primary"
            onClick={onBack}
          >
            Back
          </Button>
          <Button
            sx={buttonSpacing}
            id="send"
            variant="contained"
            color="primary"
            onClick={sendOrder}
            disabled={shouldDisableSend}
          >
            Send
          </Button>
        </div>
      </Box>
    </Box>
  );
};

export default SendYourCapsule;
