import React from "react";
import { styled } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import Footer from "../Footer";
import LeftDrawerOptions from "./LeftDrawerOptions";

import Logo from "../../Assets/images/vtc-logo.png";
import Routes from "./Routes";

const PREFIX = "RootApp";

const classes = {
  root: `${PREFIX}-root`,
  appBar: `${PREFIX}-appBar`,
  account: `${PREFIX}-account`,
  drawer: `${PREFIX}-drawer`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  menuButton: `${PREFIX}-menuButton`,
  content: `${PREFIX}-content`,
  toolbar: `${PREFIX}-toolbar`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "flex",
  },

  [`& .${classes.appBar}`]: {
    zIndex: theme.zIndex.drawer + 1,
    marginLeft: drawerWidth,
  },

  [`& .${classes.account}`]: {
    width: "auto",
  },

  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },

  [`.${classes.drawerPaper}`]: {
    marginTop: appBarHeight,
    width: drawerWidth,
  },

  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  [`& .${classes.content}`]: {
    marginTop: 64,
    flexGrow: 1,
    overflow: "auto",
  },

  [`& .${classes.toolbar}`]: {
    ...theme.mixins.toolbar,
    minHeight: appBarHeight,
  },
}));

const drawerWidth = 240;
const appBarHeight = 64;

class RootApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      authState: "",
      mobileOpen: false,
    };

    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
  }

  handleDrawerToggle() {
    this.setState({
      mobileOpen: !this.state.mobileOpen,
    });
  }

  render() {
    const { container } = this.props;

    return (
      <Root className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              edge="start"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
              size="large"
            >
              <MenuIcon />
            </IconButton>

            <IconButton>
              <img
                src={Logo}
                alt="Virtual Time Capsule Logo"
                style={{ width: "45px", height: "45px" }}
              />
            </IconButton>
            <Typography
              variant="h6"
              noWrap
              sx={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
              }}
            >
              Virtual Time Capsule
            </Typography>
          </Toolbar>
        </AppBar>
        <LeftDrawerOptions
          classes={classes}
          container={container}
          mobileOpen={this.state.mobileOpen}
          handleDrawerToggle={this.handleDrawerToggle}
        />
        <main className={classes.content}>
          <Routes />
          <Footer />
        </main>
      </Root>
    );
  }
}

export default RootApp;
