import React from "react";
import "../../App.css";
import { Route, Switch } from "react-router-dom";
import Blog from "../../Pages/Blog";
import Profile from "../../Pages/Profile";
import BlogArticle from "../../Pages/BlogArticle";
import Support from "../../Pages/Support";
import Playground from "../../Pages/Playground";
import New from "../../Pages/New";
import SignUp from "../Auth/SignUp";
import Login from "../Auth/Login";
import ForgotPassword from "../Auth/ForgotPassword";
import ProtectedRoute, {
  RedirectToDashboardOnActiveSessionRoute,
} from "./ProtectedRoute";
import ResetPassword from "../Auth/ResetPassword";
import Home from "../../Pages/Home";
import CapsuleDetails from "../Capsules/CapsuleDetails";

interface RoutesProps {}

const Routes: React.FC<RoutesProps> = () => {
  return (
    <Switch>
      {/* Currently, there is no mapping for "/"
      Does it make sense to make the Home Component URL "/" instead of "/Dashboard"? */}
      <RedirectToDashboardOnActiveSessionRoute
        path="/login"
        render={(props) => <Login {...props} />}
        exact
      />
      <RedirectToDashboardOnActiveSessionRoute
        path="/sign-up"
        render={(props) => <SignUp {...props} />}
        exact
      />
      <ProtectedRoute path="/_playground" render={() => <Playground />} exact />
      <ProtectedRoute path="/" render={(props) => <Home {...props} />} exact />
      <ProtectedRoute
        path="/Blog"
        render={(props) => <Blog {...props} />}
        exact
      />
      <ProtectedRoute
        path="/Dashboard"
        render={(props) => <Home {...props} />}
        exact
      />
      <ProtectedRoute
        path="/Blog/BlogArticle"
        render={(props) => <BlogArticle {...props} />}
        exact
      />
      <ProtectedRoute
        path="/Profile"
        render={(props) => <Profile {...props} />}
        exact
      />
      <ProtectedRoute
        path="/Support"
        render={(props) => <Support {...props} />}
        exact
      />
      <ProtectedRoute
        path="/New"
        render={(props) => <New {...props} />}
        exact
      />
      <ProtectedRoute
        path="/capsule/:id"
        render={(props) => <CapsuleDetails {...props} />}
        exact
      />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />
    </Switch>
  );
};

export default Routes;
