import { PostgrestError } from "@supabase/supabase-js";
import { Database, Json } from "../types/database.types";
import { AppResponse } from "../types/request";
import { SupabaseService } from "./service";
import { Recipient } from "../types/types";

export type Contact = Database["public"]["Tables"]["contacts"]["Row"] & {
  user: { email: string };
};

export default class ContactsService extends SupabaseService {
  getContacts = async (): AppResponse<Contact[]> => {
    const { data, error } = await this.supabase
      .from("contacts")
      .select("*, user:contactId(email)")
      .returns<Contact[]>();

    if (error) {
      return { error, data: null };
    }

    return {
      data,
      error: null,
    };
  };

  /**
   * Creates a contact if one doesn't exist for this recipient/user. If one does exist, updates the name
   * @param recipient The recipient to make into a contact
   * @param userId The current user
   * @returns The recipient's user ID if successful, or an error if one occurred
   */
  createContact = async (recipient: Recipient, userId: string) => {
    const {
      firstName,
      lastName,
      email,
      backupEmail,
      phoneNumber,
      backupPhoneNumber,
    } = recipient;

    const { data: recipientId, error } = await this.supabase.rpc(
      "add_contact",
      {
        user_id: userId,
        params: {
          firstName: firstName,
          lastName: lastName,
          email: email,
          backupEmail: backupEmail,
          phoneNumber: phoneNumber,
          backupPhoneNumber: backupPhoneNumber,
        } as Json,
      },
    );

    if (error) {
      return { data: null, error };
    }

    return { data: recipientId, error: null };
  };

  /**
   * Update a contact in supabase
   * @param contact The contact to update
   * @param firstName The new first name
   * @param lastName The new last name
   * @returns an error if one occurred
   */
  updateContact = async (
    contact: Contact,
    firstName: string | null,
    lastName: string | null,
  ): Promise<PostgrestError | null> => {
    const { error } = await this.supabase
      .from("contacts")
      .update({ firstName: firstName, lastName: lastName })
      .eq("userId", contact.userId)
      .eq("contactId", contact.contactId);

    return error;
  };
}
