import { useCallback, useState } from "react";
import { useApi } from "../index";
import { v4 as uuidv4 } from "uuid";

type UploadPath = "capsule_assets" | "profile_avatars";

export interface UploadParam {
  file?: File;
  blob?: Blob;
}

export interface Upload {
  id: string;
  objectKey: string;
  status: "uploading" | "success" | "error";
  error?: Error;
  metadata: UploadMetadata;
}

interface Dimensions {
  width: number;
  height: number;
}

interface UploadMetadata {
  file?: File;
  blob?: Blob;
  dimensions?: Dimensions;
}

const getVideoDimensions = async (file): Promise<Dimensions> => {
  const url = URL.createObjectURL(file);
  const videoElement = document.createElement("video");
  videoElement.src = url;
  return new Promise((resolve) => {
    videoElement.addEventListener("loadedmetadata", function () {
      resolve({
        width: this.videoWidth,
        height: this.videoHeight,
      });
    });
  });
};

const getDimensionsFromBlob = async (blob) => {
  if (blob.type.startsWith("video")) {
    return await getVideoDimensions(blob);
  }

  return undefined;
};

export default function useFileUpload(path: UploadPath) {
  const [uploads, setUploads] = useState<Upload[]>([]);
  const api = useApi();

  const handleDirectUpload = useCallback(
    async (upload: Upload) => {
      try {
        const directUploadResponse = await api.storage.upload({
          name: upload.objectKey,
          file: upload.metadata.file,
          blob: upload.metadata.blob,
          contentType:
            upload.metadata.file?.type || upload.metadata.blob?.type || "",
        });
        if (directUploadResponse.error) {
          throw directUploadResponse.error;
        }

        setUploads((prevUploads) =>
          prevUploads.map((prevUpload) => {
            if (prevUpload.id === upload.id) {
              return {
                ...prevUpload,
                status: "success",
              };
            }

            return prevUpload;
          }),
        );
      } catch (uploadError) {
        setUploads((prevUploads) =>
          prevUploads.map((prevUpload) => {
            if (prevUpload.id === upload.id) {
              return {
                ...prevUpload,
                status: "error",
                error: uploadError as Error,
              };
            }

            return prevUpload;
          }),
        );
      }
    },
    [setUploads],
  );

  const upload = useCallback(async (assets: UploadParam[]) => {
    let newUploads: Upload[] = [];

    for (const asset of assets) {
      const assetId = uuidv4();
      const dimensions = await getDimensionsFromBlob(asset.blob);
      newUploads.push({
        id: assetId,
        objectKey: `${path}/${assetId}`,
        status: "uploading",
        metadata: {
          file: asset.file,
          blob: asset.blob,
          dimensions,
        },
      });
    }

    setUploads((prevUploads) => [...prevUploads, ...newUploads]);

    newUploads.forEach((upload) => {
      handleDirectUpload(upload);
    });
  }, []);

  const removeUpload = useCallback(
    (id: string) => {
      setUploads((prevUploads) =>
        prevUploads.filter((upload) => upload.id !== id),
      );
    },
    [setUploads],
  );

  const resetUploads = useCallback(() => {
    setUploads([]);
  }, [setUploads]);

  return { uploads, upload, removeUpload, resetUploads };
}
