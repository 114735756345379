import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useState } from "react";
import strings from "../../common/strings";
import { withRouter } from "react-router-dom";
import { Capsule } from "../../Api/capsules";
import * as Sentry from "@sentry/react";
import api from "../../Api";

interface DeleteCapsuleProps {
  /**
   * Selected capsule
   */
  capsule: Capsule;

  history: any;
}

/**
 * Delete capsule button and confirmation popup
 */
const DeleteCapsule: React.FC<DeleteCapsuleProps> = (props) => {
  const { history, capsule } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const onDelete = async () => {
    const errors = await api.capsules.deleteCapsule(capsule);
    if (errors) {
      errors.forEach((error) => {
        Sentry.captureException(error);
      });
    }
    closeDialog();
    history.goBack();
  };

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        aria-labelledby="pictureUpdateTitle"
        scroll="paper"
      >
        <DialogTitle id="pictureUpdateTitle">
          {strings.capsule_details.DELETE_CONFIRMATION}
        </DialogTitle>
        <DialogContent>{strings.ACTION_CANNOT_BE_UNDONE}</DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} variant="contained" color="secondary">
            {strings.CANCEL}
          </Button>
          <Button onClick={onDelete} variant="contained" color="error">
            {strings.capsule_details.DELETE_CAPSULE}
          </Button>
        </DialogActions>
      </Dialog>
      <Button variant="contained" color="error" onClick={openDialog}>
        {strings.DELETE}
      </Button>
    </>
  );
};

export default withRouter(DeleteCapsule);
