import React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material";
import { Button } from "@mui/material";
import RecordButton from "./record-button";
import StopButton from "./stop-button";
import Timer from "./timer";
import Countdown from "./countdown";

const PromptWrapper = styled("div")({
  position: "absolute",
  left: "2%",
  right: "2%",
  top: "5%",
  textAlign: "center",
  backgroundColor: "#f3f3f3",
  color: "#020202",
  borderRadius: "16px",
  fontWeight: "bold",
  padding: "1rem",
  opacity: "0.8",
});

const ActionsWrapper = styled("div")({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: "5%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const VideoRecorderActions = ({
  t,
  isVideoInputSupported,
  isInlineRecordingSupported,
  thereWasAnError,
  isRecording,
  isCameraOn,
  streamIsReady,
  isConnecting,
  isRunningCountdown,
  isReplayingVideo,
  countdownTime,
  timeLimit,
  showReplayControls,
  replayVideoAutoplayAndLoopOff,
  useVideoInput,

  onTurnOnCamera,
  onTurnOffCamera,
  onOpenVideoInput,
  onStartRecording,
  onStopRecording,
  onPauseRecording,
  onResumeRecording,
  onStopReplaying,
  onConfirm,
  prompt,
}) => {
  const renderContent = () => {
    const shouldUseVideoInput =
      !isInlineRecordingSupported && isVideoInputSupported;

    if (
      (!isInlineRecordingSupported && !isVideoInputSupported) ||
      thereWasAnError ||
      isConnecting ||
      isRunningCountdown
    ) {
      return null;
    }

    if (isReplayingVideo) {
      return (
        <Button
          id="use-other-video"
          variant="contained"
          color="primary"
          onClick={onStopReplaying}
          data-qa="start-replaying"
        >
          {t("Use another video")}
        </Button>
      );
    }

    if (isRecording) {
      return (
        <StopButton
          type="button"
          onClick={onStopRecording}
          data-qa="stop-recording"
        />
      );
    }

    if (isCameraOn && streamIsReady) {
      return (
        <RecordButton
          t={t}
          type="button"
          onClick={onStartRecording}
          data-qa="start-recording"
        />
      );
    }

    if (useVideoInput) {
      return (
        <Button type="button" onClick={onOpenVideoInput} data-qa="open-input">
          {t("Upload A Video")}
        </Button>
      );
    }

    return shouldUseVideoInput ? (
      <Button
        id="use-other-video"
        variant="contained"
        color="primary"
        onClick={onOpenVideoInput}
        data-qa="open-input"
      >
        {t("Record A Video")}
      </Button>
    ) : (
      <Button
        id="use-other-video"
        variant="contained"
        color="primary"
        onClick={onTurnOnCamera}
        data-qa="turn-on-camera"
      >
        {t("Turn On Camera")}
      </Button>
    );
  };

  return (
    <div>
      {isRecording && <Timer timeLimit={timeLimit} />}
      {isRunningCountdown && <Countdown countdownTime={countdownTime} />}
      <PromptWrapper>{prompt.name}</PromptWrapper>
      <ActionsWrapper>{renderContent()}</ActionsWrapper>
    </div>
  );
};

VideoRecorderActions.propTypes = {
  t: PropTypes.func,
  isVideoInputSupported: PropTypes.bool,
  isInlineRecordingSupported: PropTypes.bool,
  thereWasAnError: PropTypes.bool,
  isRecording: PropTypes.bool,
  isCameraOn: PropTypes.bool,
  streamIsReady: PropTypes.bool,
  isConnecting: PropTypes.bool,
  isRunningCountdown: PropTypes.bool,
  countdownTime: PropTypes.number,
  timeLimit: PropTypes.number,
  showReplayControls: PropTypes.bool,
  replayVideoAutoplayAndLoopOff: PropTypes.bool,
  isReplayingVideo: PropTypes.bool,
  useVideoInput: PropTypes.bool,

  onTurnOnCamera: PropTypes.func,
  onTurnOffCamera: PropTypes.func,
  onOpenVideoInput: PropTypes.func,
  onStartRecording: PropTypes.func,
  onStopRecording: PropTypes.func,
  onPauseRecording: PropTypes.func,
  onResumeRecording: PropTypes.func,
  onStopReplaying: PropTypes.func,
  onConfirm: PropTypes.func,
  prompt: PropTypes.object,
};

export default VideoRecorderActions;
