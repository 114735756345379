import { Button, styled } from "@mui/material";
import { UserData, UserFormData } from "../../types/types";
import { useEffect, useState } from "react";
import { UserSchema } from "../../utils/validation";
import PhoneNumber from "../Shared/Forms/PhoneNumber";
import strings from "../../common/strings";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import api from "../../Api";
import { parsePhoneNumber } from "libphonenumber-js";
import NameFields from "../Shared/Forms/NameFields";
import EmailField from "../Shared/Forms/EmailField";
import toast from "react-hot-toast";
import ButtonWrapper from "../Shared/ButtonWrapper";

const UserFormRoot = styled("form", {
  name: "UserForm",
  slot: "root",
})(({ theme }) => ({
  paddingTop: theme.spacing(1),
  minWidth: theme.spacing(30),
}));

interface UserFormProps {
  /**
   * Initial user, used as default form values
   */
  user: UserData;
}

/**
 * Creates a form to display and capture basic user data (name, email, phone)
 */
const UserForm: React.FC<UserFormProps> = (props) => {
  const { user } = props;

  const [isEditMode, setIsEditMode] = useState(false);
  const [defaultFormValues, setDefaultFormValues] = useState<UserFormData>({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phoneNumber: user.phoneNumber ? user.phoneNumber : "",
  });

  const {
    register,
    formState: { errors, dirtyFields, isDirty, isValid },
    getValues,
    handleSubmit,
    reset,
    control,
  } = useForm({
    resolver: zodResolver(UserSchema),
    defaultValues: defaultFormValues,
    mode: "onBlur",
  });

  useEffect(() => {
    // Reset form whenever default values change to keep isDirty calculations up to date
    reset(defaultFormValues);
  }, [defaultFormValues]);

  const onEditProfile = () => {
    setIsEditMode(true);
  };

  const onCancel = () => {
    reset(defaultFormValues);
    setIsEditMode(false);
  };

  const updateUser = async () => {
    const newValues = getValues();
    const { firstName, lastName, phoneNumber, email } = newValues;
    let needToUpdate = true;

    /**
     * phone from getValues is formatted differently than phone from user,
     * so check that it's truly changed if it's the only dirty field
     */
    const parsedNumber = parsePhoneNumber(phoneNumber);
    if (dirtyFields.phoneNumber && Object.keys(dirtyFields).length === 1) {
      needToUpdate = parsedNumber.number !== defaultFormValues.phoneNumber;
    }

    if (needToUpdate) {
      const updatedUser: UserData = {
        ...user,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: parsedNumber.number,
      };
      const error = await api.auth.updateUserInfo(updatedUser, dirtyFields);
      if (error) {
        toast.error(strings.profile.SAVE_FAILED);
      } else {
        setDefaultFormValues(newValues);
        toast.success(strings.profile.SAVE_SUCCESSFUL);
      }
    }
  };

  const onSubmit = () => {
    if (isDirty) {
      updateUser();
    }

    // Always go back to read only
    setIsEditMode(false);
  };

  return (
    <UserFormRoot onSubmit={handleSubmit(onSubmit)}>
      <NameFields
        register={register}
        isReadOnly={!isEditMode}
        errors={errors}
        hasDefaultValue
      />
      <EmailField
        register={register}
        isReadOnly={!isEditMode}
        emailError={errors.email}
        hasDefaultValue
      />
      <PhoneNumber isReadOnly={!isEditMode} control={control} />
      <ButtonWrapper>
        {isEditMode ? (
          <>
            <Button variant="contained" color="secondary" onClick={onCancel}>
              {strings.CANCEL}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isValid}
            >
              {strings.profile.SAVE_PROFILE}
            </Button>
          </>
        ) : (
          <Button variant="contained" color="primary" onClick={onEditProfile}>
            {strings.profile.EDIT_PROFILE}
          </Button>
        )}
      </ButtonWrapper>
    </UserFormRoot>
  );
};

export default UserForm;
