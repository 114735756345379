import { styled } from "@mui/material";

const ButtonWrapper = styled("div", {
  name: "ButtonWrapper",
  slot: "root",
})(({ theme }) => ({
  display: "flex",
  [`button`]: {
    marginLeft: theme.spacing(1),
  },
  [`button:first-of-type`]: {
    marginLeft: "auto",
  },
}));

export default ButtonWrapper;
