import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const PREFIX = "AboutBeta";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  heading: `${PREFIX}-heading`,
  secondaryHeading: `${PREFIX}-secondaryHeading`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
  expDetails: `${PREFIX}-expDetails`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: "100%",
    TextAlign: "left",
  },

  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },

  [`& .${classes.header}`]: {
    paddingLeft: "2vw",
    color: "#c9c9c9",
    fontSize: "2.5vw",
  },

  [`& .${classes.content}`]: {
    padding: "20px",
  },

  [`& .${classes.heading}`]: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },

  [`& .${classes.secondaryHeading}`]: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  [`& .${classes.buttons}`]: {
    display: "flex",
    justifyContent: "flex-end",
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.expDetails}`]: {
    backgroundColor: "#c9c9c9",
  },
}));

//TODO typography header can be its own class that takes props

class AboutBeta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: null,
      open: true,
    };
  }

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const {} = this.props;

    return (
      <Root className="home-container">
        <div className="row aln-center">
          <div className="col-12-wide col-10-normal ">
            <Paper className={classes.paper}>
              <List className={classes.root}>
                <div className={classes.content}>
                  <Typography component="h1" variant="h4" align="center">
                    <b>Virtual Time Capsule Beta 2</b>
                  </Typography>
                  <Typography component="h1" variant="h6" align="left">
                    <b>05/27/2019</b>
                  </Typography>
                  <Typography component="h1" variant="h5" align="left">
                    <p>
                      <b>Virtual Time Capsule inital release</b>
                    </p>
                  </Typography>
                  <Typography variant="subtitle1">
                    <p>
                      This is our initial MVP and beta test for the Virtual Time
                      Capsule system. At this point, not every feature has been
                      fully built and implemented. We ask that you bear with us
                      as we test things out and bring in new features. We are
                      always seeking to bring in new innovations and features to
                      give you an unforgettable experience. Feel free to let us
                      know if you encounter any bugs or things that don't work.
                      We always love to hear feedback.{" "}
                    </p>
                  </Typography>
                  <Typography component="h1" variant="h6" align="left">
                    <b>Things that are still a work in progress</b>
                  </Typography>
                  <Typography variant="subtitle1">
                    <p>
                      While we're still in beta some features are not finished.
                    </p>
                    <ul>
                      <li>Time Capsule Delevery</li>
                      <li>Checkout and Payment</li>
                    </ul>

                    <p>
                      Our delivery system for Time Capsules has been rolled out
                      in this version. For now, time capsules cannot be sent
                      sooner than two days out since our system will need some
                      time to process everything. They will also be limited to 5
                      years out from the date of sending. This is just the
                      initial release of the delivery system, so features are
                      still basic. We hope to bring you new and exciting
                      features soon! As always let us know if you run into any
                      problems or have suggestions, we always love your
                      feedback.
                    </p>

                    <p>
                      The checkout process is not set up to handle payments yet,
                      so all of our beta testers get to try everything for free
                      while we finish things up. You don't have to fill in the
                      credit card details to finish sending a time capsule,
                      leave it blank. You only need to fill in recipient
                      information to complete that part of the process. We left
                      everything in even though most of it goes nowhere just to
                      show what the full checkout process will look like and see
                      what people think of it.
                    </p>
                  </Typography>
                </div>
              </List>
            </Paper>
          </div>
        </div>
      </Root>
    );
  }
}

AboutBeta.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default AboutBeta;
