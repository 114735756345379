import MuiPhoneNumber from "material-ui-phone-number";
import strings from "../../../common/strings";
import { Control, useController } from "react-hook-form";
import FormFieldWrapper from "./FormFieldWrapper";

interface PhoneNumberProps {
  /**
   * Control object from useForm, used to link the phone's controller to form validation
   */
  control: Control<any>;

  /**
   * Whether the phone number input should be read-only or editable
   */
  isReadOnly?: boolean;
}

/**
 * Creates a formatted Phone number input
 */
const PhoneNumber: React.FC<PhoneNumberProps> = (props) => {
  const { isReadOnly, control } = props;

  const {
    field: { name, value, onBlur, onChange, ref },
    fieldState: { error },
  } = useController({
    name: "phoneNumber",
    control: control,
    rules: { required: true },
  });

  return (
    <FormFieldWrapper>
      <MuiPhoneNumber
        name={name}
        fullWidth
        required
        variant="outlined"
        defaultCountry={"us"}
        label={strings.PHONE}
        disableAreaCodes
        preferredCountries={["us"]}
        disabled={isReadOnly}
        error={!!error}
        helperText={error ? error.message : ""}
        value={value}
        disableDropdown={isReadOnly}
        onChange={onChange}
        onBlur={onBlur}
        InputLabelProps={{ shrink: true, ref: ref }}
      />
    </FormFieldWrapper>
  );
};

export default PhoneNumber;
