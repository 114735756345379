import React, { useCallback } from "react";
import { styled } from "@mui/material/styles";
import { Paper, useMediaQuery, useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import classNames from "classnames";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import strings from "../../common/strings";
import { useForm } from "react-hook-form";
import { parsePhoneNumber } from "libphonenumber-js";
import toast from "react-hot-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import { SignUpSchema } from "../../utils/validation";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useApi } from "../../Api";
import PhoneNumber from "../Shared/Forms/PhoneNumber";
import NameFields from "../Shared/Forms/NameFields";
import EmailField from "../Shared/Forms/EmailField";

const PREFIX = "SignUp";

const classes = {
  root: `${PREFIX}-root`,
  margin: `${PREFIX}-margin`,
  textField: `${PREFIX}-textField`,
  paper: `${PREFIX}-paper`,
  sub: `${PREFIX}-sub`,
  button: `${PREFIX}-button`,
  button2: `${PREFIX}-button2`,
  phone: `${PREFIX}-phone`,
};

const Root = styled("form")(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: "flex",
    flexWrap: "wrap",
  },

  [`& .${classes.margin}`]: {
    margin: "8px",
    marginLeft: 0,
  },

  [`& .${classes.textField}`]: {
    flexBasis: 200,
  },

  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
  },

  [`& .${classes.sub}`]: {
    fontSize: "16px",
    margin: "2px",
    lineHeight: "4px",
  },

  [`& .${classes.button}`]: {
    textAlign: "center",
    marginBottom: "10px",
  },

  [`& .${classes.button2}`]: {
    textAlign: "left",
    marginBottom: "8px",
    marginTop: "8px",
  },

  [`& .${classes.phone}`]: {
    width: "100%",
    marginTop: "8px",
    marginBottom: "8px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "4px",
    fontSize: "16px",
    height: "56px",
    lineHeight: "19px",
    borderColor: "rgba(0, 0, 0, 0.23)",
    textIndent: "14px",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
  },
}));

const SignUp = ({}: any) => {
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
  } = useForm({
    resolver: zodResolver(SignUpSchema),
    defaultValues: {
      email: email || "",
      password: "",
      password2: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
    },
    mode: "onBlur",
  });
  const api = useApi();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showPassword2, setShowPassword2] = React.useState(false);

  const signup = useCallback(
    async ({
      email,
      password,
      password2,
      phoneNumber,
      firstName,
      lastName,
    }) => {
      const phoneNumberDetails = parsePhoneNumber(phoneNumber);
      const { error } = await api.auth.signup({
        email,
        password,
        password2,
        firstName,
        lastName,
        phoneNumber: phoneNumberDetails.number,
      });

      if (error) {
        toast.error(error?.message || strings.SIGN_UP_FAILED);
      } else {
        toast.success(strings.SIGN_UP_SUCCESSFUL);
        reset();
      }
    },
    [],
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Root onSubmit={handleSubmit(signup as any)}>
      <div
        className="login-container"
        style={{
          width: `${isMobile ? "100%" : "525px"}`,
        }}
      >
        <div className="row gtr-150">
          <div className="col-12-wide col-10-normal">
            <Paper className={classes.paper}>
              <h2>Create Account</h2>
              <EmailField register={register} emailError={errors.email} />
              <TextField
                {...register("password")}
                required={true}
                error={!!errors.password}
                helperText={errors.password?.message as string}
                className={classNames(classes.margin, classes.textField)}
                variant="outlined"
                type={showPassword ? "text" : "password"}
                label="Password"
                fullWidth
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        aria-label="Toggle password visibility"
                        size="large"
                        tabIndex={-1}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                {...register("password2")}
                required={true}
                error={!!errors.password2}
                helperText={errors.password2?.message as string}
                className={classNames(classes.margin, classes.textField)}
                variant="outlined"
                type={showPassword2 ? "text" : "password"}
                label="Confirm Password"
                fullWidth
                autoComplete="new-password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowPassword2(!showPassword2);
                        }}
                        aria-label="Toggle password visibility"
                        size="large"
                        tabIndex={-1}
                      >
                        {showPassword2 ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <NameFields register={register} errors={errors} />
              <PhoneNumber control={control} />
              <div className={classes.button2}>
                <Button variant="contained" color="primary" type="submit">
                  Sign Up
                </Button>
              </div>
              <p className={classes.sub} style={{ marginTop: "16px" }}>
                Already have an account?{" "}
                <Button color="primary" href="/login">
                  Log In
                </Button>
              </p>
              <p className={classes.sub}>
                Forgot your password?{" "}
                <Button color="primary" href="/forgot-password">
                  Reset Password
                </Button>
              </p>
            </Paper>
          </div>
        </div>
      </div>
    </Root>
  );
};

// @ts-ignore
export default SignUp;
