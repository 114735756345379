import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material";
import ProfilePicture from "../Shared/ProfilePicture";
import { ChangeEvent, useState } from "react";
import api from "../../Api";
import strings from "../../common/strings";
import toast from "react-hot-toast";

const classes = {
  profilePictureUpload: "profilePictureUpload",
  imagePreview: "imagePreview",
  profilePictureUpdate: "profilePictureUpdate",
};
const UpdateDialog = styled("div", {
  name: "UpdateDialog",
  slot: "root",
})(({ theme }) => ({
  [`.${classes.profilePictureUpload}`]: {
    font: "inherit",
  },

  [`.${classes.imagePreview}`]: {
    margin: theme.spacing(1) + " auto 0",
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
}));

interface ProfilePictureUpdateProps {
  /**
   * auth ID of the user to retrieve the profile picture of, will look up logged in user's if not passed in
   */
  authId?: string;

  /**
   * The path to the user's avatar, from the public.users table
   */
  avatarPath?: string;
}

/**
 * Component for updating the user's profile picture
 */
const ProfilePictureUpdate: React.FC<ProfilePictureUpdateProps> = (props) => {
  const { authId, avatarPath } = props;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState<string>();
  const [newImage, setNewImage] = useState<File>();
  const [picturePath, setPicturePath] = useState<string | undefined>(
    avatarPath,
  );

  const onOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setImagePreview(undefined);
    setNewImage(undefined);
  };

  const onCancel = () => {
    closeDialog();
  };

  const updateImage = async () => {
    if (newImage) {
      const newAvatarPath = await api.storage.updateAvatar(
        newImage,
        authId,
        picturePath,
      );

      if (!newAvatarPath) {
        toast.error(strings.profile.UPLOAD_FAILED);
      } else {
        setPicturePath(newAvatarPath);
        toast.success(strings.profile.UPLOAD_SUCCESSFUL);
      }
    }
  };

  const onChangeImage = () => {
    closeDialog();
    updateImage();
  };

  const onFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const picture = e.target.files![0];
      setNewImage(picture);
      setImagePreview(URL.createObjectURL(picture));
    }
  };

  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={onCancel}
        aria-labelledby="pictureUpdateTitle"
        scroll="paper"
      >
        <UpdateDialog>
          <DialogTitle id="pictureUpdateTitle">
            {strings.profile.UPLOAD_PICTURE_DIALOG_TITLE}
          </DialogTitle>
          <DialogContent>
            <input
              accept="image/*"
              type="file"
              onChange={onFileInput}
              className={classes.profilePictureUpload}
              autoFocus
            />
            {imagePreview && (
              <Avatar
                className={classes.imagePreview}
                src={imagePreview}
              ></Avatar>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} variant="contained" color="secondary">
              {strings.CANCEL}
            </Button>
            <Button onClick={onChangeImage} variant="contained" color="primary">
              {strings.profile.CHANGE_IMAGE}
            </Button>
          </DialogActions>
        </UpdateDialog>
      </Dialog>
      <Button
        variant="outlined"
        color="secondary"
        className={classes.profilePictureUpdate}
        onClick={onOpenDialog}
      >
        <ProfilePicture avatarPath={picturePath} />
        {strings.profile.UPDATE_PICTURE}
      </Button>
    </>
  );
};

export default ProfilePictureUpdate;
