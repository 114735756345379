// Home.tsx
import React, { useEffect, useState } from "react";
import "../css/Home.css";
import CreateNewTimeCapsule from "../Components/CreateNewTimeCapsule/CreateNewTimeCapsule";
import { Capsule } from "../Api/capsules";
import CapsulesList, {
  CapsulesListType,
} from "../Components/Capsules/CapsulesList";
import { withRouter } from "react-router-dom";
import { useContactsList } from "../contexts/ContactsListContext";
import { useApi } from "../Api";

interface HomeProps {
  history: any;
}

const Home: React.FC<HomeProps> = (props) => {
  const api = useApi();
  const [sentCapsules, setSentCapsules] = useState<Capsule[]>([]);
  const [receivedCapsules, setReceivedCapsules] = useState<Capsule[]>([]);

  const [isLoadingSentCapsules, setIsLoadingSentCapsules] =
    useState<boolean>(false);
  const [isLoadingReceivedCapsules, setIsLoadingReceivedCapsules] =
    useState<boolean>(false);
  const [loadingSentCapsulesError, setLoadingSentCapsulesError] =
    useState<boolean>(false);
  const [loadingReceivedCapsulesError, setLoadingReceivedCapsulesError] =
    useState<boolean>(false);

  const fetchSentCapsulesData = async () => {
    setIsLoadingSentCapsules(true);
    const { data, error } = await api.capsules.getSentCapsules();
    setIsLoadingSentCapsules(false);
    if (error) {
      setLoadingSentCapsulesError(true);
      return;
    }
    setSentCapsules(data);

    /**
     * Legacy GA Code
    window.scrollTo(0, 0);
    initGA("UA-138986572-1");
    PageView();
     *
     */
  };

  const fetchReceivedCapsulesData = async () => {
    setIsLoadingReceivedCapsules(true);
    const { data, error } = await api.capsules.getReceivedCapsules();
    setIsLoadingReceivedCapsules(false);
    if (error) {
      setLoadingReceivedCapsulesError(true);
      return;
    }
    setReceivedCapsules(data);
  };

  useEffect(() => {
    fetchSentCapsulesData();
    fetchReceivedCapsulesData();
  }, [setSentCapsules, setReceivedCapsules]);

  const handleCapsuleSelected = (capsule: Capsule) => {
    props.history.push(`/capsule/${capsule.id}`);
  };

  const refreshSentCapsulesList = () => {
    fetchSentCapsulesData();
  };

  return (
    <div className="home-container">
      <CreateNewTimeCapsule refreshSentCapsulesList={refreshSentCapsulesList} />

      <CapsulesList
        capsulesListType={CapsulesListType.SentCapsules}
        capsules={sentCapsules}
        isLoadingCapsules={isLoadingSentCapsules}
        handleCapsuleSelected={handleCapsuleSelected}
        loadingCapsulesError={loadingSentCapsulesError}
      ></CapsulesList>

      <CapsulesList
        capsulesListType={CapsulesListType.ReceivedCapsules}
        capsules={receivedCapsules}
        isLoadingCapsules={isLoadingReceivedCapsules}
        handleCapsuleSelected={handleCapsuleSelected}
        loadingCapsulesError={loadingReceivedCapsulesError}
      ></CapsulesList>
    </div>
  );
};

export default withRouter(Home);
