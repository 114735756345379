import { styled } from "@mui/material";

const FormFieldWrapper = styled("div", {
  name: "FormField",
  slot: "root",
})(({ theme }) => ({
  padding: theme.spacing(1, 0),
}));

export default FormFieldWrapper;
