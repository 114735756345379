import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { GetThemeResponseItem } from "../../../../Api/themes";
import { Tabs, Theme, useMediaQuery } from "@mui/material";

import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ThemesPanelCategories from "./ThemesPanelCategories";
import ThemesPanelPrompts from "./ThemesPanelPrompts";

const PREFIX = "ThemesPanel";
const classes = {};

const Root = styled("div")(({ theme }) => ({}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Root
      role="tabpanel"
      hidden={value !== index}
      id={`theme-tabpanel-${index}`}
      aria-labelledby={`theme-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Root>
  );
};

function a11yProps(index: any) {
  return {
    id: `theme-tab-${index}`,
    "aria-controls": `theme-tabpanel-${index}`,
  };
}

enum CapsulePromptTheme {
  Storytelling = 0,
  Questions,
  Journaling,
  Reflection,
}

interface ThemesPanelProps {
  themes: GetThemeResponseItem[];
  onPromptSelected: any;
}

const ThemesPanel = ({ themes, onPromptSelected }: ThemesPanelProps) => {
  const [activeTheme, setActiveTheme] = useState(
    CapsulePromptTheme.Storytelling,
  );
  const [themeCategoriesOpen, setThemeCategoriesOpen] = useState(
    themes.reduce((dictionary, theme) => {
      dictionary[theme.id] = "";
      return dictionary;
    }, {}),
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTheme(newValue);
  };

  const handlePromptSelected = (prompt) => {
    onPromptSelected(prompt);
  };

  const onCategoryToggled = (category) => {
    let newValue =
      themeCategoriesOpen[category.themeId] === category.id ? "" : category.id;
    setThemeCategoriesOpen((prevThemeCategoriesOpen) => ({
      ...prevThemeCategoriesOpen,
      [category.themeId]: newValue,
    }));
  };

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm"),
  );

  return (
    <div>
      <Tabs
        value={activeTheme}
        onChange={handleChange}
        variant={isMobile ? "scrollable" : "standard"}
        scrollButtons={isMobile}
        allowScrollButtonsMobile={isMobile}
        centered={!isMobile}
      >
        {/* Populate Theme tabs based on server data */}
        {themes.map((theme, index) => (
          <Tab label={theme.name} key={theme.id} {...a11yProps(index)} />
        ))}
      </Tabs>

      {/* Create a panel for each theme */}
      {themes.map((theme, index) => (
        <TabPanel value={activeTheme} key={theme.id + "panel"} index={index}>
          {/* @ts-ignore */}
          {theme.hasCategories && (
            <ThemesPanelCategories
              categories={theme.categories}
              key={theme.id + "categories"}
              handlePromptSelected={handlePromptSelected}
              handleCategoryToggled={onCategoryToggled}
              themeCategoryOpen={themeCategoriesOpen[theme.id]}
            ></ThemesPanelCategories>
          )}

          {/* @ts-ignore */}
          {!theme.hasCategories && (
            <ThemesPanelPrompts
              prompts={theme.prompts}
              handlePromptSelected={handlePromptSelected}
            ></ThemesPanelPrompts>
          )}
        </TabPanel>
      ))}
    </div>
  );
};

// @ts-ignore
export default ThemesPanel;
