import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Event } from "../Tracking/Tracking";

const PREFIX = "DevContact";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  heading: `${PREFIX}-heading`,
  secondaryHeading: `${PREFIX}-secondaryHeading`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
  expDetails: `${PREFIX}-expDetails`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: "100%",
    TextAlign: "left",
  },

  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },

  [`& .${classes.header}`]: {
    paddingLeft: "2vw",
    color: "#c9c9c9",
    fontSize: "2.5vw",
  },

  [`& .${classes.content}`]: {
    padding: "20px",
  },

  [`& .${classes.heading}`]: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },

  [`& .${classes.secondaryHeading}`]: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  [`& .${classes.buttons}`]: {
    display: "flex",
    justifyContent: "flex-end",
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.expDetails}`]: {
    backgroundColor: "#c9c9c9",
  },
}));

//TODO typography header can be its own class that takes props

class DevContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: null,
      open: true,
    };
  }

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };
  handleClick() {
    Event("Button", "Dev twitter", "Support");
    window.open("https://twitter.com/vtcdevs", "_blank");
  }

  render() {
    const {} = this.props;

    return (
      <Root className="home-container">
        <div className="row aln-center">
          <div className="col-12-wide col-10-normal ">
            <Paper className={classes.paper}>
              <List className={classes.root}>
                <div className={classes.content}>
                  <Typography component="h1" variant="h4" align="center">
                    <b>From the VTC developers</b>
                  </Typography>
                  <Typography component="h1" variant="h5" align="left">
                    <p>
                      <b>We want your feedback</b>
                    </p>
                  </Typography>

                  <Typography variant="subtitle1">
                    <ul>
                      <li>
                        <a>vtc_dev@vtcapsules.com</a>
                      </li>
                      <li>
                        <a onClick={this.handleClick}>
                          Dev / Tech support Twitter
                        </a>
                      </li>
                    </ul>
                    <p>
                      While trying out our website you can contact us directly
                      if you run into any problems or need any kind of technical
                      support. If you find any bugs or find something we need to
                      address, you can email us directly with the email above or
                      let us know through twitter. We're a small company with
                      only a couple of developers so your feedback means a lot
                      to us! We want to give you the best experience possible,
                      so don't hesitate to let us know about any problems you're
                      having or even if you don't like the way we're building
                      things.{" "}
                    </p>
                  </Typography>
                </div>
              </List>
            </Paper>
          </div>
        </div>
      </Root>
    );
  }
}

DevContact.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default DevContact;
