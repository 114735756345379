import { SupabaseService } from "./service";
import { Database, Json } from "../types/database.types";
import { CreateCapsuleParams, CreateCapsuleSchema } from "../utils/validation";
import StorageService from "./storage";
import { PostgrestError, SupabaseClient } from "@supabase/supabase-js";
import { AttachmentType } from "../types/types";
import ContactsService from "./contacts";
import { v4 as uuidv4 } from "uuid";

export enum CapsuleStatus {
  DRAFT = "draft",
  SCHEDULED = "scheduled",
  SENT = "sent",
  RECEIVED = "received",
  OPENED = "opened",
}

enum MemoryStatus {
  COMPLETE = "complete",
}

export type AttachmentBlob =
  Database["public"]["Tables"]["attachment_blobs"]["Row"];
export type Attachment = Database["public"]["Tables"]["attachments"]["Row"] & {
  blob: AttachmentBlob;
};

type Recipient = Database["public"]["Tables"]["capsule_recipients"]["Row"];

export type Capsule = Database["public"]["Tables"]["capsules"]["Row"] & {
  attachments: Attachment[];
  recipients: Recipient[];
};

export default class CapsulesService extends SupabaseService {
  storage: StorageService;
  contacts: ContactsService;

  constructor(
    supabase: SupabaseClient,
    storage: StorageService,
    contacts: ContactsService,
  ) {
    super(supabase);
    this.storage = storage;
    this.contacts = contacts;
  }

  createCapsule = async (params: CreateCapsuleParams) => {
    console.log("Send Capsule: Starting to Create Capsule");
    const result = CreateCapsuleSchema.safeParse(params);
    if (!result.success) {
      console.log(
        `Send Capsule: ${Date.now()} - Invalid params. Error: ${result.error} `,
      );
      const parseError = {
        message: "Invalid params. Please review the details and try again.",
        details: result.error,
      };
      return parseError;
    }

    const { user, error: getUserError } = await this.getUser();
    console.log(
      `Send Capsule: ${Date.now()} - Get User error. Error: ${getUserError} `,
    );
    if (getUserError) {
      return getUserError;
    }
    const { id: userId, membershipId } = user;
    const { data } = result;
    const { recipient, capsule, recipientUserId } = data;

    let recipientId: string = "";
    if (recipient) {
      const { data: newContactId, error: newContactError } =
        await this.contacts.createContact(recipient, userId);

      if (newContactError) {
        console.log(
          `Send Capsule: ${Date.now()} - New Contact Error. Error: ${newContactError} `,
        );
        return newContactError;
      }

      recipientId = newContactId;
    } else if (recipientUserId) {
      recipientId = recipientUserId;
    }

    const {
      id: capsuleId,
      title,
      message,
      sendDate,
      attachments,
      gifPreview,
      imageThumbnail,
    } = capsule;

    const { error: capsuleError } = await this.supabase
      .from("capsules")
      .insert({
        id: capsuleId,
        title: title,
        message: message,
        sendDate: sendDate.toISOString(),
        sendStatus: CapsuleStatus.SCHEDULED,
        senderId: userId,
        recipientId,
        membershipId: membershipId,
      })
      .select()
      .single();

    if (capsuleError) {
      console.log(
        `Send Capsule: ${Date.now()} - Insert Capsule. Error: ${capsuleError} `,
      );
      return capsuleError;
    }

    // Insert memory and memory content
    const memoryContentId = uuidv4();
    await this.createMemoryV1(
      capsuleId,
      userId,
      title,
      message,
      memoryContentId,
    );

    const attachmentsToUpload = [...attachments, gifPreview, imageThumbnail];

    // Insert attachment blobs
    const { data: attachmentBlobs, error: attachmentBlobsError } =
      await this.supabase
        .from("attachment_blobs")
        .insert(
          attachmentsToUpload.map(({ order, type, ...attachment }) => ({
            ...attachment,
          })),
        )

        .select();

    if (attachmentBlobsError) {
      return attachmentBlobsError;
    }

    // Map attachments to expected form, and keep track of the attachment ID for memory content
    const contentAttachmentId = uuidv4();
    const attachmentsToInsert = attachmentsToUpload.map((attachment) => {
      // Use memoryContentId and contentAttachmentId only for the attachment type
      let attachmentId: string;
      let contentId: string | undefined;
      if (attachment.type === "attachment") {
        attachmentId = contentAttachmentId;
        contentId = memoryContentId;
      } else {
        attachmentId = uuidv4();
      }

      return {
        id: attachmentId,
        capsuleId: capsuleId,
        blobId: attachmentBlobs.find((blob) => blob.key === attachment.key)
          ?.id!,
        order: attachment.order,
        type: attachment.type,
        contentId: contentId,
      };
    });

    // Insert attachments
    const { error: attachmentsError } = await this.supabase
      .from("attachments")
      .insert(attachmentsToInsert)
      .select();

    if (attachmentsError) {
      return attachmentsError;
    }

    // Insert recipients
    const { error: recipientsError } = await this.supabase
      .from("capsule_recipients")
      .insert({ capsuleId: capsuleId, recipientId: recipientId });

    if (recipientsError) {
      return recipientsError;
    }
    return null;
  };

  getSentCapsules = async () => {
    const { user, error: getUserError } = await this.getUser();
    if (getUserError) {
      return { error: getUserError, data: null };
    }
    return this.supabase
      .from("capsules")
      .select(
        `*, attachments:attachments(*, blob:attachment_blobs(*)), recipients:capsule_recipients(*)`,
      )
      .eq("senderId", user.id)
      .returns<Capsule[]>();
  };

  getReceivedCapsules = async () => {
    const { user, error: getUserError } = await this.getUser();
    if (getUserError) {
      return { error: getUserError, data: null };
    }
    return this.supabase
      .from("capsules")
      .select(
        `*, attachments:attachments(*, blob:attachment_blobs(*)), recipients:capsule_recipients!inner(*)`,
      )
      .eq("capsule_recipients.recipientId", user.id)
      .eq("sendStatus", "sent")
      .returns<Capsule[]>();
  };

  getCapsuleById = async (capsuleId: string) => {
    return await this.supabase
      .from("capsules")
      .select(
        `*, attachments:attachments(*, blob:attachment_blobs(*)), recipients:capsule_recipients(*)`,
      )
      .eq("id", capsuleId)
      .returns<Capsule>()
      .single();
  };

  getCapsuleAttachmentUrl = async (fileObjectKey: string) => {
    const { data, error } = await this.storage.getReadSignedUrl(fileObjectKey);

    if (!error) {
      return {
        error: null,
        signedUrl: data.signedUrl,
      };
    }

    return { error, signedUrl: null };
  };

  getCapsuleThumbnailUrl = async (thumbnailPath: string) => {
    const { data, error } =
      await this.storage.getCapsuleVideoThumbnailSignedUrl(thumbnailPath);

    if (error && !data) {
      return { error };
    }

    return { data, error: null };
  };

  /**
   * Deletes a capsule record in supabase
   * @param capsuleId ID of the capsule
   * @returns An error if one occurred
   */
  private deleteCapsuleRecord = async (capsuleId: string) => {
    const { error } = await this.supabase
      .from("capsules")
      .delete()
      .eq("id", capsuleId);

    return error;
  };

  /**
   * Deletes a capsule and its related attachments
   * @param capsule The capsule to be deleted
   * @returns An array of errors that occurred, null if no errors
   */
  deleteCapsule = async (
    capsule: Capsule,
  ): Promise<(Error | PostgrestError)[] | null> => {
    const { attachments, id } = capsule;
    const thumbnailsToDelete: string[] = [];
    const errors: (Error | PostgrestError)[] = [];

    // Use format s3 expects for attachments
    const attachmentsToDelete: { Key: string }[] = [];

    attachments.forEach((attachment) => {
      const { type, blob } = attachment;
      if (type === AttachmentType.ATTACHMENT) {
        attachmentsToDelete.push({ Key: blob.key });
      } else {
        thumbnailsToDelete.push(blob.key);
      }
    });

    // Delete thumbnails in supabase
    if (thumbnailsToDelete.length > 0) {
      const error = await this.storage.deleteThumbnails(thumbnailsToDelete);
      if (error) {
        errors.push(error);
      }
    }

    // Delete attachments in s3
    if (attachmentsToDelete.length > 0) {
      const error = await this.storage.deleteAttachments(attachmentsToDelete);
      if (error) {
        errors.push(error);
      }
    }

    // Delete capsule
    const error = await this.deleteCapsuleRecord(id);
    if (error) {
      errors.push(error);
    }

    if (errors.length > 0) {
      return errors;
    }

    return null;
  };

  /**
   * Update a capsule in supabase
   * @param capsuleId ID of the capsule to be updated
   * @param message updated message
   * @param sendDateISO updated send date
   * @param recipientId updated recipient ID
   * @returns an error if one occurred
   */
  updateCapsule = async (
    capsuleId: string,
    message?: string,
    sendDateISO?: string,
    recipientId?: string,
  ): Promise<PostgrestError | null> => {
    const { error } = await this.supabase
      .from("capsules")
      .update({
        message: message,
        recipientId: recipientId,
        sendDate: sendDateISO,
        updatedAt: new Date().toISOString(),
      })
      .eq("id", capsuleId);

    return error;
  };

  /**
   * Updates a capsule recipient in supabase
   * @param capsuleId ID of capsule to update recipients for
   * @param newRecipientId ID of new recipient
   * @param oldRecipientId ID of old recipient
   * @returns an error if one occurred
   */
  updateCapsuleRecipient = async (
    capsuleId: string,
    newRecipientId: string,
    oldRecipientId: string,
  ): Promise<PostgrestError | null> => {
    const { error } = await this.supabase
      .from("capsule_recipients")
      .update({
        recipientId: newRecipientId,
      })
      .eq("capsuleId", capsuleId)
      .eq("recipientId", oldRecipientId);

    return error;
  };

  /**
   * Creates a memory and its content from a v1 capsule
   * @param capsuleId capsule ID
   * @param userId sender ID
   * @param title title of capsule
   * @param message message of capsule, used as description of memory
   * @param contentId ID to use for the memory content
   */
  createMemoryV1 = async (
    capsuleId: string,
    userId: string,
    title: string,
    message: string,
    contentId: string,
  ) => {
    const memoryId = uuidv4();
    await this.supabase.from("memories").insert({
      id: memoryId,
      userId: userId,
      capsuleId: capsuleId,
      title: title,
      description: message,
      status: MemoryStatus.COMPLETE,
    });

    await this.createMemoryContentV1(memoryId, userId, contentId);
  };

  /**
   * Creates a memory content from a v1 capsule/attachment
   * @param memoryId memory ID
   * @param userId sender ID
   * @param contentId ID to use for this content
   */
  createMemoryContentV1 = async (
    memoryId: string,
    userId: string,
    contentId: string,
  ) => {
    await this.supabase.from("memory_content").insert({
      id: contentId,
      memoryId: memoryId,
      userId: userId,
    });
  };
}
