import { createClient, SupabaseClient } from "@supabase/supabase-js";
import { SupabaseService } from "./service";
import AuthService from "./auth";
import ThemesService from "./themes";
import CapsulesService from "./capsules";
import { Database } from "../types/database.types";
import StorageService from "./storage";
import ContactsService from "./contacts";
import React from "react";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL!;
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY!;
const supabase = createClient<Database>(supabaseUrl, supabaseKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true,
    flowType: "implicit",
  },
});

class Api extends SupabaseService {
  storage: StorageService;
  auth: AuthService;
  themes: ThemesService;
  capsules: CapsulesService;
  contacts: ContactsService;

  constructor(_supabase: SupabaseClient) {
    super(_supabase);

    this.storage = new StorageService(_supabase);
    this.auth = new AuthService(_supabase, this.storage);
    this.themes = new ThemesService(_supabase);
    this.contacts = new ContactsService(_supabase);
    this.capsules = new CapsulesService(_supabase, this.storage, this.contacts);
  }

  getClient() {
    return this.supabase;
  }
}

const api = new Api(supabase);
// DEPRECATED - Use useApi() instead.
export default api;

export const ApiContext = React.createContext({ api });

export const useApi = () => {
  const { api } = React.useContext(ApiContext);
  return api;
};

supabase.auth.onAuthStateChange(async (event, session) => {
  if (event === "INITIAL_SESSION") {
    // This sets the membershipId on the user's metadata on Supabase
    // membership is required for RLS policies
    await api.auth.initializeUserWithMembership();
  }
});
