import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

interface RecordedVideoContextType {
  videoBlob: any;
  setVideoBlob: Dispatch<SetStateAction<Blob>>;
}

// Context
export const RecordedVideoContext = createContext<
  RecordedVideoContextType | undefined
>(undefined);

// Provider
export const RecordedVideoProvider = ({ children }) => {
  const [videoBlob, setVideoBlob] = useState<any>(null);

  return (
    <RecordedVideoContext.Provider value={{ videoBlob, setVideoBlob }}>
      {children}
    </RecordedVideoContext.Provider>
  );
};

// Hook
export function useRecordedVideo(): RecordedVideoContextType {
  const context = useContext(RecordedVideoContext);
  if (!context) {
    throw new Error(
      "useRecordedVideo must be used within a RecordedVideoProvider",
    );
  }
  return context;
}
