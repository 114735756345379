import strings from "../../../common/strings";
import { Control, Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import FormFieldWrapper from "./FormFieldWrapper";

interface DateFieldProps {
  /**
   * Initial user, used as default form values
   */
  control: Control<any>;

  /**
   * Error for the date field (not a simple FieldError, so use any type)
   */
  dateError?: any;
}

/**
 * Creates a form to display and capture capsule data (recipient, send date, message)
 */
const DateField: React.FC<DateFieldProps> = (props) => {
  const { control, dateError } = props;

  const maxDate = dayjs().add(5, "year").month(11).date(31);

  return (
    <FormFieldWrapper>
      <Controller
        control={control}
        name="sendDate"
        rules={{ required: true }}
        render={({ field: { onChange, ref, value, onBlur } }) => {
          return (
            <DatePicker
              value={value}
              disablePast
              maxDate={dayjs(maxDate)}
              label={strings.SEND_DATE}
              onChange={onChange}
              inputRef={ref}
              slotProps={{
                textField: {
                  onBlur,
                  required: true,
                  error: !!dateError,
                  helperText: dateError?.message,
                },
              }}
            ></DatePicker>
          );
        }}
      />
    </FormFieldWrapper>
  );
};

export default DateField;
