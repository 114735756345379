import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import React, { ChangeEvent, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { useCreateCapsule } from "../../../contexts/CreateCapsuleContext";
import { isFileUploadSizeValid } from "../../../utils/file";

const PREFIX = "SelectCapsuleVideo";
const classes = {
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
  filePicker: `${PREFIX}-filePicker`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.buttons}`]: {
    display: "flex",
    justifyContent: "flex-end",
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.filePicker}`]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    border: "1px dashed #c4c4c4",
    borderRadius: "8px",
    backgroundColor: "#f5f5f5",
    paddingTop: "50px",
    paddingBottom: "50px",
  },
}));

const getFriendlyFileSize = (bytes: number, decimals = 1) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export default function SelectCapsuleVideo({
  handleBack,
  handleContinue,
}: {
  handleBack: any;
  handleContinue: any;
}) {
  const { capsuleFormInfo, setCapsuleFormInfo } = useCreateCapsule();

  function selectUploadFile(file: File) {
    setCapsuleFormInfo((prevState) => {
      return {
        ...prevState,
        file: file,
      };
    });
  }

  const onFileSelected = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    selectUploadFile(file);
  }, []);

  return (
    <Root>
      <Grid>
        <div className={classes.filePicker}>
          <form encType="multipart/form-data">
            <input
              accept="video/*, video/mp4, video/x-msvideo, video/x-ms-wmv, video/x-flv"
              type="file"
              onChange={onFileSelected}
            />
          </form>
          <Typography gutterBottom align="center">
            *Accepted file types: mp4, mov, flv, avi, wmv, mpeg, mpg{" "}
          </Typography>
        </div>
        <div className="container">
          {capsuleFormInfo.file && (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>File Name</TableCell>
                  <TableCell align="right">Size</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th">
                    {capsuleFormInfo.file.name}
                  </TableCell>
                  <TableCell align="right">
                    {getFriendlyFileSize(capsuleFormInfo.file.size)}
                    {!isFileUploadSizeValid(capsuleFormInfo.file) && (
                      <Typography color="error">
                        File size is too large
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </div>
      </Grid>
      <div className={classes.buttons}>
        <Button
          id="cancel"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={handleBack}
        >
          Cancel
        </Button>
        <Button
          id="next"
          variant="contained"
          color="primary"
          className={classes.button}
          disabled={!capsuleFormInfo.file}
          onClick={handleContinue}
        >
          Next
        </Button>
      </div>
    </Root>
  );
}
