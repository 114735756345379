import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../Api/hooks/useAuth";

const ProtectedRoute = ({ render: render, ...rest }) => {
  const { session } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        session ? (
          render(props)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export const RedirectToDashboardOnActiveSessionRoute = ({
  render: render,
  ...rest
}) => {
  const { session } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        !session ? (
          render(props)
        ) : (
          <Redirect
            to={{
              pathname: "/dashboard",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
