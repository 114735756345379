import React, { Component } from "react";
import "../css/Home.css";
import AboutSending from "../Components/Support/AboutSending";
import AboutBeta from "../Components/Support/AboutBeta";
import DevContact from "../Components/Support/DevContact";
import { PageView, initGA } from "../Components/Tracking/Tracking";
import SupportTabs from "../Components/Support/SupportTabs";
import Updates from "../Components/Support/Updates";

class New extends Component {
  componentDidMount() {
    initGA("UA-138986572-1");
    PageView();
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <Updates />
      </div>
    );
  }
}

export default New;
