import React from "react";
import { Prompt } from "../../../../Api/themes";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { ListItemButton, useTheme } from "@mui/material";

interface ThemesPanelPromptsProps {
  prompts: Prompt[];
  handlePromptSelected: any;
}

const ThemesPanelPrompts = ({
  prompts,
  handlePromptSelected,
}: ThemesPanelPromptsProps) => {
  const onPromptSelected = (prompt) => {
    handlePromptSelected(prompt);
  };

  const theme = useTheme();

  return (
    <List>
      {/* Create a list of prompts */}
      {prompts.map((prompt) => (
        <ListItemButton
          key={prompt.id}
          onClick={() => onPromptSelected(prompt)}
        >
          <ListItemText
            primaryTypographyProps={{
              paddingLeft: theme.spacing(2),
            }}
            primary={prompt.name}
          />
        </ListItemButton>
      ))}
    </List>
  );
};

// @ts-ignore
export default ThemesPanelPrompts;
