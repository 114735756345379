import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import classNames from "classnames";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import { Paper, useMediaQuery, useTheme } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import { ForgotPasswordSchema } from "../../utils/validation";
import toast from "react-hot-toast";
import strings from "../../common/strings";
import { useApi } from "../../Api";

const PREFIX = "ForgotPassword";

const classes = {
  root: `${PREFIX}-root`,
  margin: `${PREFIX}-margin`,
  textField: `${PREFIX}-textField`,
  paper: `${PREFIX}-paper`,
  sub: `${PREFIX}-sub`,
  button: `${PREFIX}-button`,
  button2: `${PREFIX}-button2`,
  phone: `${PREFIX}-phone`,
};

const Root = styled("form")(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: "flex",
    flexWrap: "wrap",
  },

  [`& .${classes.margin}`]: {
    margin: "8px",
    marginLeft: 0,
  },

  [`& .${classes.textField}`]: {
    flexBasis: 200,
  },

  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
  },

  [`& .${classes.sub}`]: {
    fontSize: "16px",
    margin: "2px",
  },

  [`& .${classes.button}`]: {
    textAlign: "center",
    marginBottom: "10px",
  },

  [`& .${classes.button2}`]: {
    textAlign: "left",
    marginBottom: "8px",
    marginTop: "8px",
  },

  [`& .${classes.phone}`]: {
    width: "100%",
    marginTop: "8px",
    marginBottom: "8px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "4px",
    fontSize: "16px",
    height: "56px",
    lineHeight: "19px",
    borderColor: "rgba(0, 0, 0, 0.23)",
    textIndent: "14px",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
  },
}));

const ForgotPassword = ({}: any) => {
  const api = useApi();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: zodResolver(ForgotPasswordSchema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = useCallback(async ({ email }) => {
    const { error } = await api.auth.sendPasswordResetEmail(
      email,
      `${window.location.origin}/reset-password`,
    );

    if (error) {
      toast.error(strings.FORGOT_PASSWORD_FAILED);
    } else {
      toast.success(strings.FORGOT_PASSWORD_SUCCESSFUL);
      reset();
    }
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Root onSubmit={handleSubmit(onSubmit)}>
      <div
        className="login-container"
        style={{
          width: `${isMobile ? "100%" : "525px"}`,
        }}
      >
        <div className="row gtr-150">
          <div className="col-12-wide col-10-normal">
            <Paper className={classes.paper}>
              <h2>Reset your password</h2>
              <p className={classes.sub} style={{ marginBottom: "32px" }}>
                Type in your email and we'll send you a link to reset your
                password
              </p>
              <TextField
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email?.message as string}
                id="outlined-adornment-username"
                className={classNames(classes.margin, classes.textField)}
                variant="outlined"
                label="Email"
                autoComplete="email"
                fullWidth
              />

              <div className={classes.button2}>
                <Button variant="contained" color="primary" type="submit">
                  Send Reset Email
                </Button>
              </div>
              <p className={classes.sub} style={{ marginTop: "16px" }}>
                Already have an account?{" "}
                <Button href="/sign-up" color="primary">
                  Sign Up
                </Button>
              </p>
            </Paper>
          </div>
        </div>
      </div>
    </Root>
  );
};

export default ForgotPassword;
