import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Capsule } from "../../Api/capsules";
import CapsuleSummary from "./CapsuleSummary";
import strings from "../../common/strings";

export enum CapsulesListType {
  ReceivedCapsules = 0,
  SentCapsules,
}

enum SortOptions {
  SortByDateUpdatedAtRecentsFirst = "SortByDateUpdatedAtRecentsFirst",
}

interface CapsulesListProps {
  capsulesListType: CapsulesListType;
  capsules: Capsule[];
  isLoadingCapsules: boolean;
  handleCapsuleSelected: any;
  loadingCapsulesError: boolean;
  sortOptions?: SortOptions;
}

const CapsulesList: React.FC<CapsulesListProps> = ({
  capsules,
  capsulesListType,
  isLoadingCapsules,
  handleCapsuleSelected,
  loadingCapsulesError,
  sortOptions = SortOptions.SortByDateUpdatedAtRecentsFirst, // Default to sorting by date updated
}) => {
  const getTitle = () => {
    if (capsulesListType === CapsulesListType.SentCapsules) {
      return strings.SENT_CAPSULES_LIST_TITLE;
    } else if (capsulesListType === CapsulesListType.ReceivedCapsules) {
      return strings.RECEIVED_CAPSULES_LIST_TITLE;
    }
  };

  const getEmptyMessage = () => {
    if (capsulesListType == CapsulesListType.SentCapsules) {
      return strings.EMPTY_SENT_CAPSULES_LIST_MESSAGE;
    } else if (capsulesListType === CapsulesListType.ReceivedCapsules) {
      return strings.EMPTY_RECEIVED_CAPSULES_LIST_MESSAGE;
    }
  };

  const onCapsuleSelected = (capsule: Capsule) => {
    handleCapsuleSelected(capsule);
  };

  // Sort capsules based on sortOptions
  const sortedCapsules = () => {
    switch (sortOptions) {
      case SortOptions.SortByDateUpdatedAtRecentsFirst:
        return capsules.slice().sort((a, b) => {
          return (
            new Date(b.updatedAt || "1970-01-01").getTime() -
            new Date(a.updatedAt || "1970-01-01").getTime()
          );
        });
      // Add more cases for additional sorting options if needed
      default:
        return capsules;
    }
  };

  // State to hold the sorted capsules
  const [sortedCapsulesList, setSortedCapsulesList] = useState<Capsule[]>(
    sortedCapsules(),
  );

  // Update sortedCapsulesList when capsules or sortOptions change
  useEffect(() => {
    setSortedCapsulesList(sortedCapsules());
  }, [capsules, sortOptions]);

  return (
    <div>
      <h2>{getTitle()}</h2>
      {isLoadingCapsules ? (
        <p>Loading Capsules...</p>
      ) : loadingCapsulesError ? (
        <div>
          <p>
            Sorry! There was an error loading your capsules. Please try again.
          </p>
          <p>If issues persist, please contact us for support.</p>
        </div>
      ) : capsules.length === 0 ? (
        <p>{getEmptyMessage()}</p>
      ) : (
        <Grid
          container
          justifyContent={"flex-start"}
          alignItems={"center"}
          spacing={3}
        >
          {sortedCapsulesList.map((capsule) => (
            <Grid item key={capsule.id} xs={12} sm={6} md={6} lg={4}>
              <CapsuleSummary
                capsule={capsule}
                handleCapsuleSelected={onCapsuleSelected}
              ></CapsuleSummary>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};

export default CapsulesList;
