import React, { Component } from "react";
import "../App.css";
import BlogLayout from "../Components/Blog/BlogLayout";
import { withAuthenticator } from "aws-amplify-react";
import { Link, Redirect } from "react-router-dom";
import Button from "@mui/material/Button";
import Login from "./Login.js";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /*
  async componentDidMount() {
    //this.getUser().then(() => {
      if(this.props.auth == false) {
        //console.log("Home IF " + this.props.auth)
        console.log("IF")
      }
      else if (this.state.user.length <= 0 && (this.props.auth == true || this.props.auth == false)) {
        //window.location.reload();
        console.log("ELSE IF")
      }
      else {
        console.log("ELSE")
      }
    //});
  
  }
  */

  render() {
    //console.log("BLOG " + this.props.auth)

    if (this.props.auth == false) {
      return <Login />;
    } else if (this.props.user.length || this.props.userID.length <= 0) {
      return <div>Loading Blog...</div>;
    } else if (this.props.auth == true) {
      return (
        <div className="App">
          <BlogLayout />
        </div>
      );
    }
  }
}

export default Blog;
