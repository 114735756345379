import React, { createRef, useCallback, useEffect, useState } from "react";
import { Capsule } from "../../Api/capsules";
import { Box, Skeleton, useTheme } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { grey } from "@mui/material/colors";
import { Paper, Typography } from "@mui/material";
import { useApi } from "../../Api";
import ReactFreezeframe from "react-freezeframe";
import { getCapsuleThumbnailKey } from "../../utils/capsules";

interface CapsuleSummaryProps {
  capsule: Capsule;
  handleCapsuleSelected: any;
}

const CapsuleSummary: React.FC<CapsuleSummaryProps> = ({
  capsule,
  handleCapsuleSelected,
}) => {
  const theme = useTheme();
  const [videoGifUrl, setVideoGifUrl] = useState<string>();
  const api = useApi();
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isGifLoading, setIsGifLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const freeze = createRef<ReactFreezeframe>();

  const fetchData = useCallback(async () => {
    const thumbnailPath = getCapsuleThumbnailKey(capsule);
    if (!thumbnailPath) {
      setError(true);
      return;
    }

    const { data, error } =
      await api.capsules.getCapsuleThumbnailUrl(thumbnailPath);
    if (error) {
      setError(true);
      return;
    }
    setVideoGifUrl(data.signedUrl);
    setError(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const styles = {
    container: {
      height: theme.spacing(45),
      backgroundColor: isHovered ? `${grey[100]}` : "white",
      transition: "background-color 0.3s",
      my: theme.spacing(1), // top/bottom margin
      padding: theme.spacing(2),
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      cursor: "pointer",

      [theme.breakpoints.up("md")]: {
        minWidth: theme.spacing(22),
        minHeight: theme.spacing(24),
        maxWidth: theme.spacing(45),
        maxHeight: theme.spacing(42),
      },
      [theme.breakpoints.up("sm")]: {
        minWidth: theme.spacing(32),
        minHeight: theme.spacing(31),
        maxWidth: theme.spacing(50),
        maxHeight: theme.spacing(45),
      },
      [theme.breakpoints.up("xs")]: {
        minWidth: theme.spacing(40),
        minHeight: theme.spacing(30),
        maxWidth: theme.spacing(66),
        maxHeight: theme.spacing(45),
      },
    },
    loadingSpinnerContainer: {
      display: "flex",
      justifyContent: "center",
      marginY: theme.spacing(2),
    },
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
    freeze.current?.start();
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
    freeze.current?.stop();
  };

  return (
    <Paper
      sx={styles.container}
      key={capsule.id}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}
      onClick={() => handleCapsuleSelected(capsule)}
    >
      {error && (
        <Box display="flex" flexDirection="column" justifyContent="center">
          <Box sx={styles.loadingSpinnerContainer}>
            <ErrorIcon color="error"></ErrorIcon>
          </Box>
          <Typography variant="subtitle1" align="center">
            Uh Oh!
          </Typography>
          <Typography variant="subtitle2" align="center">
            Sorry, there was an error loading your capsule preview.
          </Typography>
        </Box>
      )}

      <Box display={"flex"} justifyContent="center">
        {videoGifUrl && (
          <ReactFreezeframe
            ref={freeze}
            options={{
              trigger: false,
              overlay: false,
            }}
          >
            <img
              src={videoGifUrl}
              style={{ width: "100%", maxHeight: theme.spacing(32) }}
              onLoad={() => setIsGifLoading(false)}
              hidden={isGifLoading}
            />
          </ReactFreezeframe>
        )}
        {isGifLoading && !error && (
          <Skeleton
            variant="rectangular"
            style={{ width: "100%", height: theme.spacing(32) }}
          />
        )}
      </Box>
      <Typography textAlign="center" noWrap variant="h5">
        {capsule.title}
      </Typography>
    </Paper>
  );
};

export default CapsuleSummary;
