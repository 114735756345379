import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import { Link, Redirect } from "react-router-dom";
import classNames from "classnames";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import { Auth } from "aws-amplify";
import {
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  SignUp,
  VerifyContact,
  withAuthenticator,
  Authenticator,
  Greetings,
} from "aws-amplify-react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input/basic-input";
import "../css/login.css";
import { Paper } from "@mui/material";
const PREFIX = "Login";

const classes = {
  root: `${PREFIX}-root`,
  margin: `${PREFIX}-margin`,
  textField: `${PREFIX}-textField`,
  paper: `${PREFIX}-paper`,
  sub: `${PREFIX}-sub`,
  button: `${PREFIX}-button`,
  button2: `${PREFIX}-button2`,
  phone: `${PREFIX}-phone`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: "flex",
    flexWrap: "wrap",
  },

  [`& .${classes.margin}`]: {
    margin: "8px",
    marginLeft: 0,
  },

  [`& .${classes.textField}`]: {
    flexBasis: 200,
  },

  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
  },

  [`& .${classes.sub}`]: {
    fontSize: "16px",
    padding: "8px",
    margin: "2px",
    lineHeight: "4px",
  },

  [`& .${classes.button}`]: {
    textAlign: "center",
    marginBottom: "10px",
  },

  [`& .${classes.button2}`]: {
    textAlign: "left",
    marginBottom: "8px",
    marginTop: "8px",
  },

  [`& .${classes.phone}`]: {
    width: "100%",
    marginTop: "8px",
    marginBottom: "8px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "4px",
    fontSize: "16px",
    height: "56px",
    lineHeight: "19px",
    borderColor: "rgba(0, 0, 0, 0.23)",
    textIndent: "14px",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
  },
}));

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: [],
      username: "",
      password: "",
      password2: "",
      phone: "",
      name: "",
      code: "",
      showPassword: false,
      showPassword2: false,
      state: "signin",
      error: "",
    };
  }

  handleChange = (prop) => (event) => {
    this.setState({ [prop]: event.target.value });
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };
  handleClickShowPassword2 = () => {
    this.setState((state) => ({ showPassword2: !state.showPassword2 }));
  };

  signMeIn(username, password) {
    Auth.signIn({
      username, // Required, the username
      password, // Optional, the password
      //validationData, // Optional, a random key-value pair map which can contain any key and will be passed to your PreAuthentication Lambda trigger as-is. It can be used to implement additional validations around authentication
    })
      .then((user) => {
        this.setState({
          user: user,
          auth: true,
        });
        //console.log(this.props.auth)
        //console.log(user)
      })
      .catch((err) => {
        //console.log(err)
        if (err.message == "Network error")
          this.setState({ error: err.message });
        else this.setState({ error: "Incorrect Username or Password" });
      });

    this.setState({ auth: true });
    //console.log(this.props.auth)
  }

  //TODO display message "reset code sent"
  forgotPass(username, password) {
    Auth.forgotPassword(username)
      .then((data) => {
        console.log(data);
        this.setState({ state: "newpass" });
        this.setState({
          error: "A reset code has been sent to the email provided",
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: "Invalid Username" });
      });
  }

  //TODO display message "password reset"
  //TODO use pass1 and pass2
  resetPass(username, code, new_password) {
    //Collect confirmation code and new password, then
    Auth.forgotPasswordSubmit(username, code, new_password)
      .then((data) => {
        console.log(data);
        //this.setState({error: "A reset code has been sent to the email provided"})
        if (window.alert("Your password has been changed")) {
          //window.location.reload()
          this.setState({ state: "signin" });
        } else {
          this.setState({
            state: "signin",
            password: "",
            password2: "",
            code: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err.message });
      });
  }

  signMeUp(username, password, name, phone) {
    let email = username;
    let phone_number = "+1" + phone;
    Auth.signUp({
      username,
      password,
      attributes: {
        name,
        email, // optional
        phone_number, // optional - E.164 number convention
        // other custom attributes
      },
      validationData: [], //optional
    })
      .then((data) => {
        this.setState({ state: "confirm", code: "" });
        this.setState({
          error: "A confirmation code has been sent to your email",
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err.message });
      });
  }

  confirmSignUp(username, code) {
    // After retrieving the confirmation code from the user
    Auth.confirmSignUp(username, code, {
      // Optional. Force user confirmation irrespective of existing alias. By default set to True.
      //forceAliasCreation: true
    })
      .then((data) => {
        //console.log(data)
        this.setState({
          state: "signin",
          error: "",
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ error: err.message });
      });
  }

  resend(username) {
    Auth.resendSignUp(username)
      .then(() => {
        this.setState({ error: "code resent successfully" });
        //console.log('code resent successfully');
      })
      .catch((e) => {
        console.log(e);
        this.setState({ error: e.message });
      });
  }

  signMeOut = () => {
    Auth.signOut()
      .then(() => {
        this.setState({
          //authState: 'unauthorized',
          auth: false,
        });
      })
      .catch((err) => {
        console.log(err);

        //window.location.reload();
      });
  };

  changeForm(stateChange) {
    this.setState({ state: stateChange });
  }

  render() {
    const { user, username, password, password2, state, name, phone, code } =
      this.state;
    const {} = this.props;

    let isDisabled, isDisabled2, isDisabled3;
    if (username === "" || password === "") {
      isDisabled2 = true;
    } else {
      isDisabled2 = false;
    }

    if (
      username === "" ||
      password === "" ||
      password2 === "" ||
      name === "" ||
      phone === ""
    ) {
      isDisabled = true;
    } else if (password != password2) {
      isDisabled = true;
    } else {
      isDisabled = false;
    }

    if (username === "" || code === "" || password === "" || password2 === "") {
      isDisabled3 = true;
    } else if (password != password2) {
      isDisabled3 = true;
    } else {
      isDisabled3 = false;
    }

    let form;
    if (this.props.auth == true) {
      return <Redirect to="/Dashboard" />;
    } else if (state == "signin") {
      form = (
        <Root>
          <div className="login-container">
            <div className="row gtr-150">
              <div className="col-12-wide col-10-normal">
                <Paper className={classes.paper}>
                  <h2>Login</h2>
                  {this.state.error}

                  <TextField
                    onKeyPress={(ev) => {
                      //console.log(`Pressed keyCode ${ev.key}`);
                      if (ev.key === "Enter" && !isDisabled2) {
                        ev.preventDefault();
                        //this.continue()
                        this.signMeIn(username.toLowerCase(), password);
                      }
                    }}
                    id="outlined-adornment-username"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    label="Email"
                    fullWidth
                    value={this.state.username}
                    onChange={this.handleChange("username")}
                    //helperText="Email"
                  />

                  <TextField
                    onKeyPress={(ev) => {
                      //console.log(`Pressed keyCode ${ev.key}`);
                      if (ev.key === "Enter" && !isDisabled2) {
                        ev.preventDefault();
                        //this.continue()
                        this.signMeIn(username.toLowerCase(), password);
                      }
                    }}
                    id="outlined-adornment-password"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    type={this.state.showPassword ? "text" : "password"}
                    label="Password"
                    fullWidth
                    value={this.state.password}
                    onChange={this.handleChange("password")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            size="large"
                          >
                            {this.state.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div className={classes.button2}>
                    <Button
                      disabled={isDisabled2}
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        this.signMeIn(username.toLowerCase(), password)
                      }
                    >
                      Log In
                    </Button>
                  </div>

                  <p className={classes.sub} align="center">
                    Forgot Password?
                  </p>
                  <div className={classes.button}>
                    <Button
                      color="primary"
                      onClick={() => this.changeForm("resetpass")}
                    >
                      Reset Password
                    </Button>
                  </div>

                  <p className={classes.sub} align="center">
                    Don't have an account?
                  </p>
                  <div className={classes.button}>
                    <Button
                      color="primary"
                      onClick={() => this.changeForm("signup")}
                    >
                      Sign Up
                    </Button>
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </Root>
      );
    } else if (state == "signup") {
      form = (
        <div>
          <div className="login-container">
            <div className="row gtr-150">
              <div className="col-12-wide col-10-normal">
                <Paper className={classes.paper}>
                  <h2>Create Account</h2>
                  {this.state.error}
                  <TextField
                    onKeyPress={(ev) => {
                      //console.log(`Pressed keyCode ${ev.key}`);
                      if (ev.key === "Enter" && !isDisabled <= 0) {
                        ev.preventDefault();
                        //this.continue()
                        this.signMeUp(
                          username.toLowerCase(),
                          password,
                          name,
                          phone,
                        );
                      }
                    }}
                    id="outlined-adornment-username"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    label="Email"
                    fullWidth
                    value={this.state.username}
                    onChange={this.handleChange("username")}
                  />
                  <TextField
                    onKeyPress={(ev) => {
                      //console.log(`Pressed keyCode ${ev.key}`);
                      if (ev.key === "Enter" && !isDisabled <= 0) {
                        ev.preventDefault();
                        //this.continue()
                        this.signMeUp(
                          username.toLowerCase(),
                          password,
                          name,
                          phone,
                        );
                      }
                    }}
                    id="outlined-adornment-password"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    type={this.state.showPassword ? "text" : "password"}
                    label="Password"
                    fullWidth
                    value={this.state.password}
                    onChange={this.handleChange("password")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            size="large"
                          >
                            {this.state.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    onKeyPress={(ev) => {
                      //console.log(`Pressed keyCode ${ev.key}`);
                      if (ev.key === "Enter" && !isDisabled <= 0) {
                        ev.preventDefault();
                        //this.continue()
                        this.signMeUp(
                          username.toLowerCase(),
                          password,
                          name,
                          phone,
                        );
                      }
                    }}
                    id="outlined-adornment-password"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    type={this.state.showPassword2 ? "text" : "password"}
                    label="Confirm Password"
                    fullWidth
                    value={this.state.password2}
                    onChange={this.handleChange("password2")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword2}
                            size="large"
                          >
                            {this.state.showPassword2 ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <TextField
                    onKeyPress={(ev) => {
                      //console.log(`Pressed keyCode ${ev.key}`);
                      if (ev.key === "Enter" && !isDisabled <= 0) {
                        ev.preventDefault();
                        //this.continue()
                        this.signMeUp(
                          username.toLowerCase(),
                          password,
                          name,
                          phone,
                        );
                      }
                    }}
                    id="outlined-adornment-name"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    label="Full Name"
                    fullWidth
                    value={this.state.name}
                    onChange={this.handleChange("name")}
                  />
                  <PhoneInput
                    onKeyPress={(ev) => {
                      //console.log(`Pressed keyCode ${ev.key}`);
                      if (ev.key === "Enter" && !isDisabled <= 0) {
                        ev.preventDefault();
                        //this.continue()
                        this.signMeUp(
                          username.toLowerCase(),
                          password,
                          name,
                          phone,
                        );
                      }
                    }}
                    className={classes.phone}
                    country="US"
                    placeholder="Phone Number"
                    fullWidth
                    value={this.state.phone}
                    onChange={(phone) => this.setState({ phone })}
                  />
                  <div className={classes.button2}>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isDisabled}
                      onClick={() =>
                        this.signMeUp(
                          username.toLowerCase(),
                          password,
                          name,
                          phone,
                        )
                      }
                    >
                      Sign Up
                    </Button>
                  </div>
                  <p className={classes.sub} align="center">
                    Already have an account?
                  </p>
                  <div className={classes.button}>
                    <Button
                      color="primary"
                      onClick={() => this.changeForm("signin")}
                    >
                      Log In
                    </Button>
                  </div>
                  <p className={classes.sub} align="center">
                    Have an account but need to confirm your email?
                  </p>
                  <div className={classes.button}>
                    <Button
                      color="primary"
                      onClick={() => {
                        this.changeForm("confirm");
                        this.setState({ code: "" });
                      }}
                    >
                      Confirm Email
                    </Button>
                  </div>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (state == "confirm") {
      form = (
        <div>
          <div className="login-container">
            <div className="row gtr-150">
              <div className="col-12-wide col-10-normal">
                <Paper className={classes.paper}>
                  <h3>Confirm Account</h3>
                  <p>Check your email for confirmation code</p>
                  {this.state.error}
                  <TextField
                    onKeyPress={(ev) => {
                      //console.log(`Pressed keyCode ${ev.key}`);
                      if (ev.key === "Enter" && !this.state.code.length <= 0) {
                        ev.preventDefault();
                        //this.continue()
                        this.confirmSignUp(username.toLowerCase(), code);
                      }
                    }}
                    id="outlined-adornment-username"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    label="Email"
                    fullWidth
                    value={this.state.username}
                    onChange={this.handleChange("username")}
                  />
                  <TextField
                    onKeyPress={(ev) => {
                      //console.log(`Pressed keyCode ${ev.key}`);
                      if (ev.key === "Enter" && !this.state.code.length <= 0) {
                        ev.preventDefault();
                        //this.continue()
                        this.confirmSignUp(username.toLowerCase(), code);
                      }
                    }}
                    id="code"
                    type="password"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    label="Confirmation Code"
                    fullWidth
                    value={this.state.code}
                    onChange={this.handleChange("code")}
                  />
                  <Button
                    disabled={this.state.code.length <= 0}
                    color="primary"
                    onClick={() =>
                      this.confirmSignUp(username.toLowerCase(), code)
                    }
                  >
                    Confirm
                  </Button>
                  <Button onClick={() => this.resend(username.toLowerCase())}>
                    Resend Code
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => this.changeForm("signin")}
                  >
                    Back to Log In
                  </Button>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (state == "resetpass") {
      form = (
        <div>
          <div className="login-container">
            <div className="row gtr-150">
              <div className="col-12-wide col-10-normal">
                <Paper className={classes.paper}>
                  <h3>Enter Email of Account</h3>
                  {this.state.error}
                  <TextField
                    onKeyPress={(ev) => {
                      //console.log(`Pressed keyCode ${ev.key}`);
                      if (ev.key === "Enter" && !this.state.code.length <= 0) {
                        ev.preventDefault();
                        //this.continue()
                        this.forgotPass(username.toLowerCase());
                      }
                    }}
                    id="outlined-adornment-username"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    label="Email"
                    fullWidth
                    value={this.state.username}
                    onChange={this.handleChange("username")}
                  />
                  {/*<Button disabled={this.state.code.length <= 0} color='primary'  onClick={() => this.confirmSignUp(username.toLowerCase(), code)}>Confirm</Button>*/}
                  <Button
                    onClick={() => this.forgotPass(username.toLowerCase())}
                  >
                    Send Reset Code
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => this.changeForm("signin")}
                  >
                    Back to Log In
                  </Button>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (state == "newpass") {
      form = (
        <div>
          <div className="login-container">
            <div className="row gtr-150">
              <div className="col-12-wide col-10-normal">
                <Paper className={classes.paper}>
                  <h3>Create new Password</h3>
                  {this.state.error}
                  <TextField
                    onKeyPress={(ev) => {
                      //console.log(`Pressed keyCode ${ev.key}`);
                      if (ev.key === "Enter" && !isDisabled3) {
                        ev.preventDefault();
                        //this.continue()
                        this.resetPass(username.toLowerCase(), code, password);
                      }
                    }}
                    id="outlined-adornment-username"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    label="Email"
                    fullWidth
                    value={this.state.username}
                    onChange={this.handleChange("username")}
                  />
                  <TextField
                    onKeyPress={(ev) => {
                      //console.log(`Pressed keyCode ${ev.key}`);
                      if (ev.key === "Enter" && !isDisabled3) {
                        ev.preventDefault();
                        //this.continue()
                        this.resetPass(username.toLowerCase(), code, password);
                      }
                    }}
                    id="code"
                    type="text"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    label="Reset Code"
                    fullWidth
                    value={this.state.code}
                    onChange={this.handleChange("code")}
                  />
                  <TextField
                    onKeyPress={(ev) => {
                      //console.log(`Pressed keyCode ${ev.key}`);
                      if (ev.key === "Enter" && !isDisabled3) {
                        ev.preventDefault();
                        //this.continue()
                        this.resetPass(username.toLowerCase(), code, password);
                      }
                    }}
                    id="password"
                    type="password"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    label="New Password"
                    fullWidth
                    value={this.state.password}
                    onChange={this.handleChange("password")}
                  />
                  <TextField
                    onKeyPress={(ev) => {
                      //console.log(`Pressed keyCode ${ev.key}`);
                      if (ev.key === "Enter" && !isDisabled3) {
                        ev.preventDefault();
                        //this.continue()
                        this.resetPass(username.toLowerCase(), code, password);
                      }
                    }}
                    id="password2"
                    type="password"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    label="Confirm New Password"
                    fullWidth
                    value={this.state.password2}
                    onChange={this.handleChange("password2")}
                  />
                  {/*<Button disabled={this.state.code.length <= 0} color='primary'  onClick={() => this.confirmSignUp(username.toLowerCase(), code)}>Confirm</Button>*/}
                  <Button
                    disabled={isDisabled3}
                    onClick={() =>
                      this.resetPass(username.toLowerCase(), code, password)
                    }
                  >
                    Reset Password
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => this.changeForm("signin")}
                  >
                    Back to Log In
                  </Button>
                </Paper>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <Redirect to="/" />;
    }

    return <div>{form}</div>;
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Login;
