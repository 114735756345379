import { Paper, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import classNames from "classnames";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import strings from "../../common/strings";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useApi } from "../../Api";
import GoogleSignInButton from "./GoogleSignInButton";
const PREFIX = "Login";

const classes = {
  root: `${PREFIX}-root`,
  margin: `${PREFIX}-margin`,
  textField: `${PREFIX}-textField`,
  paper: `${PREFIX}-paper`,
  sub: `${PREFIX}-sub`,
  button: `${PREFIX}-button`,
  button2: `${PREFIX}-button2`,
  phone: `${PREFIX}-phone`,
  googleLogo: `${PREFIX}-googleLogo`,
};

const Root = styled("form")(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: "flex",
    flexWrap: "wrap",
  },

  [`& .${classes.margin}`]: {
    margin: "8px",
    marginLeft: 0,
  },

  [`& .${classes.textField}`]: {
    flexBasis: 200,
  },

  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    padding: theme.spacing(2),
  },

  [`& .${classes.sub}`]: {
    fontSize: "16px",
    margin: "2px",
    lineHeight: "4px",
  },

  [`& .${classes.button}`]: {
    textAlign: "center",
    marginBottom: "10px",
  },

  [`& .${classes.button2}`]: {
    textAlign: "left",
    marginBottom: "8px",
    marginTop: "8px",
  },

  [`& .${classes.phone}`]: {
    width: "100%",
    marginTop: "8px",
    marginBottom: "8px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRadius: "4px",
    fontSize: "16px",
    height: "56px",
    lineHeight: "19px",
    borderColor: "rgba(0, 0, 0, 0.23)",
    textIndent: "14px",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
  },
}));

const Login = ({}: any) => {
  const urlParams = new URLSearchParams(window.location.search);
  const email = urlParams.get("email");

  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: email || "",
      password: "",
    },
  });
  const [showPassword, setShowPassword] = React.useState(false);
  const api = useApi();

  const login = useCallback(async (data) => {
    const authenticatedUser = await api.auth.login(data);
    if (!authenticatedUser) {
      toast.error(strings.LOGIN_FAILED);
    }
  }, []);

  const authenticateWithGoogle = async () => {
    const { data, error } = await api.auth.authenticateWithGoogle();
    if (error) {
      // TODO: Send error report to Sentry
      console.log("Encountered an error! ", error);
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Root onSubmit={handleSubmit(login)}>
      <div
        className="login-container"
        style={{
          width: `${isMobile ? "100%" : "525px"}`,
        }}
      >
        <div className="row gtr-150">
          <div className="col-12-wide col-10-normal">
            <Paper className={classes.paper}>
              <h2>Login</h2>
              {/*{this.state.error}*/}
              <TextField
                {...register("email")}
                id="outlined-adornment-username"
                className={classNames(classes.margin, classes.textField)}
                variant="outlined"
                label="Email"
                autoComplete="email"
                fullWidth
              />
              <TextField
                {...register("password")}
                id="outlined-adornment-password"
                className={classNames(classes.margin, classes.textField)}
                variant="outlined"
                type={showPassword ? "text" : "password"}
                label="Password"
                autoComplete="current-password"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        aria-label="Toggle password visibility"
                        size="large"
                        tabIndex={-1}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <div className={classes.button2}>
                <Button variant="contained" color="primary" type="submit">
                  Login
                </Button>
              </div>
              <p className={classes.sub} style={{ marginTop: "16px" }}>
                Don't have an account?{" "}
                <Button href="/sign-up" color="primary">
                  Sign Up
                </Button>
              </p>
              <p className={classes.sub}>
                Forgot your password?{" "}
                <Button color="primary" href="/forgot-password">
                  Reset Password
                </Button>
              </p>
              <GoogleSignInButton
                authenticateWithGoogle={authenticateWithGoogle}
              ></GoogleSignInButton>
            </Paper>
          </div>
        </div>
      </div>
    </Root>
  );
};

// @ts-ignore
export default Login;
