import React from "react";
import { Collapse, ListItemButton } from "@mui/material";
import { Category, Prompt } from "../../../../Api/themes";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ThemesPanelPrompts from "./ThemesPanelPrompts";

interface ThemesPanelCategoriesProps {
  categories: (Category & { prompts: Prompt[] })[];
  handlePromptSelected: any;
  handleCategoryToggled: any;
  themeCategoryOpen: string;
}

const ThemesPanelCategories = ({
  categories,
  handlePromptSelected,
  handleCategoryToggled,
  themeCategoryOpen,
}: ThemesPanelCategoriesProps) => {
  const onPromptSelected = (prompt: Prompt) => {
    handlePromptSelected(prompt);
  };

  const onCategoryToggled = (category: Category) => {
    handleCategoryToggled(category);
  };

  const openState = (category: Category) => {
    return themeCategoryOpen === category.id;
  };

  return (
    <List>
      {/* Create a list of dropdown categories with prompts inside */}
      {categories.map((category) => (
        <div key={category.id}>
          <ListItemButton onClick={() => onCategoryToggled(category)}>
            <ListItemText
              primaryTypographyProps={{ fontWeight: "bold" }}
              primary={category.name}
            />
            {openState(category) ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openState(category)} timeout="auto" unmountOnExit>
            {/* @ts-ignore */}
            <ThemesPanelPrompts
              prompts={category.prompts}
              handlePromptSelected={onPromptSelected}
            ></ThemesPanelPrompts>
          </Collapse>
        </div>
      ))}
    </List>
  );
};

// @ts-ignore
export default ThemesPanelCategories;
