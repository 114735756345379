import React from "react";
import { BrowserRouter } from "react-router-dom";
import RootApp from "./Components/Navigation/RootApp.js";
import { ApiContext, useApi } from "./Api";
import { AuthProvider } from "./Api/hooks/useAuth";
import { Toaster } from "react-hot-toast";
import "./App.css";
import { CreateCapsuleProvider } from "./contexts/CreateCapsuleContext.tsx";
import { RecordedVideoProvider } from "./contexts/RecordedVideoContext";
import { ContactsListProvider } from "./contexts/ContactsListContext.tsx";

export default function App({ session }) {
  const api = useApi();

  return (
    <AuthProvider initialSession={session}>
      <ApiContext.Provider value={{ api: api }}>
        <RecordedVideoProvider>
          <CreateCapsuleProvider>
            <ContactsListProvider>
              <BrowserRouter>
                <div>
                  <RootApp />
                  <Toaster
                    position="top-right"
                    toastOptions={{
                      className: "",
                      style: {
                        fontSize: "14px",
                        fontWeight: "semi-bold",
                      },
                      success: {
                        duration: 5000,
                      },
                    }}
                  />
                </div>
              </BrowserRouter>
            </ContactsListProvider>
          </CreateCapsuleProvider>
        </RecordedVideoProvider>
      </ApiContext.Provider>
    </AuthProvider>
  );
}
