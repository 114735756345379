import React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
  BrowserRouter,
  Route,
  Switch,
  Link,
  withRouter,
} from "react-router-dom";
const PREFIX = "BlogCard";

const classes = {
  appBar: `${PREFIX}-appBar`,
  icon: `${PREFIX}-icon`,
  heroUnit: `${PREFIX}-heroUnit`,
  heroContent: `${PREFIX}-heroContent`,
  card: `${PREFIX}-card`,
  cardMedia: `${PREFIX}-cardMedia`,
  cardContent: `${PREFIX}-cardContent`,
};

const StyledCard = styled(Card)(({ theme }) => ({
  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.icon}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.heroUnit}`]: {
    backgroundColor: theme.palette.background.paper,
  },

  [`& .${classes.heroContent}`]: {
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing(8)} 0 ${theme.spacing(6)}`,
  },

  [`&.${classes.card}`]: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.cardMedia}`]: {
    paddingTop: "56.25%", // 16:9
  },

  [`& .${classes.cardContent}`]: {
    flexGrow: 1,
  },
}));

function BlogCard(props) {
  const {} = props;

  return (
    <StyledCard className={classes.card}>
      <CardMedia
        className={classes.cardMedia}
        image="data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22288%22%20height%3D%22225%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20288%20225%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_164edaf95ee%20text%20%7B%20fill%3A%23eceeef%3Bfont-weight%3Abold%3Bfont-family%3AArial%2C%20Helvetica%2C%20Open%20Sans%2C%20sans-serif%2C%20monospace%3Bfont-size%3A14pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_164edaf95ee%22%3E%3Crect%20width%3D%22288%22%20height%3D%22225%22%20fill%3D%22%2355595c%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%2296.32500076293945%22%20y%3D%22118.8%22%3EThumbnail%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E" // eslint-disable-line max-len
        title="Image title"
      />
      <CardContent className={classes.cardContent}>
        <Typography gutterBottom variant="h5" component="h2">
          Heading
        </Typography>
        <Typography>
          This is a media card. You can use this section to describe the
          content.
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          color="primary"
          component={Link}
          to="/Blog/BlogArticle"
        >
          View
        </Button>
        <Button size="small" color="primary">
          Edit
        </Button>
      </CardActions>
    </StyledCard>
  );
}

export default BlogCard;
