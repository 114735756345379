import { SupabaseClient, User } from "@supabase/supabase-js";

import { SupabaseService } from "./service";
import StorageService from "./storage";
import { LoginParams, SignUpParams } from "../utils/validation";
import { ChangedUserFields, UserData } from "../types/types";

export default class AuthService extends SupabaseService {
  storage: StorageService;

  constructor(supabase: SupabaseClient, storage: StorageService) {
    super(supabase);
    this.storage = storage;
  }

  initializeUserWithMembership = async () => {
    const { data, error } = await this.supabase.auth.getUser();
    if (error) {
      return error;
    }

    if (!data.user?.user_metadata?.membershipId) {
      return this.updateUserWithMembership(data.user);
    }
  };

  login = async (data: LoginParams) => {
    const { data: authenticatedUser } =
      await this.supabase.auth.signInWithPassword({
        email: data.email,
        password: data.password,
      });

    if (!authenticatedUser?.user) {
      // something went wrong - authenticated user object expected
      return null;
    }

    if (!authenticatedUser.user.user_metadata.membershipId) {
      await this.updateUserWithMembership(authenticatedUser.user);
    }

    return authenticatedUser;
  };

  authenticateWithGoogle = async (): Promise<{ data: any; error: any }> => {
    const { data, error } = await this.supabase.auth.signInWithOAuth({
      provider: "google",
    });

    if (error) {
      return { data: null, error };
    }

    return { data, error: null };
  };

  updateUserWithMembership = async (user: User) => {
    const { data: membership, error: getMembershipError } = await this.supabase
      .from("memberships")
      .select("*")
      .single();

    if (!membership) {
      return getMembershipError;
    }

    const { data, error } = await this.supabase.auth.updateUser({
      data: {
        ...user.user_metadata,
        membershipId: membership?.id,
      },
    });

    if (!data) {
      return error;
    }
  };

  signup = async (data: SignUpParams) => {
    return this.supabase.auth.signUp({
      email: data.email,
      password: data.password,
      options: {
        emailRedirectTo: window.location.origin,
        data: {
          firstName: data.firstName,
          lastName: data.lastName,
          phoneNumber: data.phoneNumber,
        },
      },
    });
  };

  sendPasswordResetEmail = async (email: string, redirectTo) => {
    return this.supabase.auth.resetPasswordForEmail(email, {
      redirectTo,
    });
  };

  /**
   * Updates the user's info with the passed in values in the auth.users table.
   * The public.users table is updated on the database whenever auth.users is updated
   * @param user public.users object with updated values
   * @param changedFields which fields have been changed and need to be updated
   * @returns an error if something went wrong
   */
  updateUserInfo = async (user: UserData, changedFields: ChangedUserFields) => {
    const { firstName, lastName, phoneNumber, email } = user;
    const { data, error } = await this.supabase.auth.updateUser({
      data: {
        firstName: changedFields.firstName ? firstName : undefined,
        lastName: changedFields.lastName ? lastName : undefined,
        phoneNumber: changedFields.phoneNumber ? phoneNumber : undefined,
      },
      email: changedFields.email ? email : undefined,
    });

    if (!data) {
      return error;
    }
  };
}
