import { useCallback, useState } from "react";
import { useApi } from "../index";
import { v4 as uuidv4 } from "uuid";
import { generateThumbnails } from "../../utils/thumbnail";

export interface UploadParam {
  capsuleId: string;
  file?: File;
  blob?: Blob;
}

export interface Dimensions {
  width: number;
  height: number;
}

export interface Upload {
  id: string;
  gifPreviewPath: string;
  imageThumbnailPath: string;
  status: "generating" | "uploading" | "success" | "error";
  error?: Error;
  metadata: UploadMetadata;
}

interface UploadMetadata {
  file?: File;
  blob?: Blob;
  gifPreviewBlob?: Blob;
  imageThumbnailBlob?: Blob;
  dimensions?: Dimensions;
}

export default function useThumbnailUpload() {
  const [uploads, setUploads] = useState<Upload[]>([]);
  const api = useApi();

  const setUpload = useCallback(
    (partialUpload: Partial<Upload>) => {
      setUploads((prevUploads) => {
        return prevUploads.map((prevUpload) => {
          const updatedUpload = prevUpload.id === partialUpload.id;
          if (updatedUpload) {
            return {
              ...prevUpload,
              ...partialUpload,
            };
          }
          return prevUpload;
        });
      });
    },
    [setUploads],
  );

  const handleGenerateThumbnail = useCallback(
    async (upload: Upload) => {
      try {
        const { imageThumbnailPath, gifPreviewPath, id, metadata } = upload;
        const thumbnails = await generateThumbnails(upload.metadata.blob, {
          debug: false,
        });
        const { gifPreviewBlob, imageThumbnailBlob, dimensions } = thumbnails;

        setUpload({ id: upload.id, status: "uploading" });
        const { error: gifPreviewError } = await api.storage.thumbnailUpload({
          name: gifPreviewPath,
          path: gifPreviewPath,
          blob: gifPreviewBlob,
          contentType: "image/gif",
        });
        if (gifPreviewError) {
          throw new Error(gifPreviewError.message);
        }

        if (imageThumbnailBlob) {
          const { error: imageThumbnailError } =
            await api.storage.thumbnailUpload({
              name: imageThumbnailPath,
              path: imageThumbnailPath,
              blob: imageThumbnailBlob,
              contentType: imageThumbnailBlob.type,
            });
          if (imageThumbnailError) {
            throw new Error(imageThumbnailError.message);
          }
        }

        setUpload({
          id: id,
          metadata: {
            ...metadata,
            gifPreviewBlob: gifPreviewBlob,
            imageThumbnailBlob: imageThumbnailBlob,
            dimensions: dimensions,
          },
          status: "success",
        });
      } catch (uploadError) {
        setUpload({
          id: upload.id,
          status: "error",
          error: uploadError as Error,
        });
      }
    },
    [setUpload],
  );

  const upload = useCallback(async (assets: UploadParam[]) => {
    let newUploads: Upload[] = assets.map((asset) => {
      const { blob, capsuleId, file } = asset;
      const assetId = uuidv4();
      return {
        id: assetId,
        gifPreviewPath: `${capsuleId}/${assetId}.gif`,
        imageThumbnailPath: `${capsuleId}/${assetId}.png`,
        status: "generating",
        metadata: {
          file: file,
          blob: blob,
        },
      };
    });

    setUploads((prevUploads) => [...prevUploads, ...newUploads]);

    newUploads.forEach((upload) => {
      handleGenerateThumbnail(upload);
    });
  }, []);

  const removeUpload = useCallback(
    (id: string) => {
      setUploads((prevUploads) =>
        prevUploads.filter((upload) => upload.id !== id),
      );
    },
    [setUploads],
  );

  const resetUploads = useCallback(() => {
    setUploads([]);
  }, [setUploads]);

  return {
    uploads,
    upload,
    removeUpload,
    resetUploads,
  };
}
