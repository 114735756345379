export default {
  // Auth
  SIGN_UP_SUCCESSFUL:
    "Sign up successful! Please check your email to verify your account.",
  SIGN_UP_FAILED: "Sign up failed. Please try again.",
  LOGIN_FAILED: "Login failed. Please try again.",
  FORGOT_PASSWORD_SUCCESSFUL: "Please check your email to reset your password.",
  FORGOT_PASSWORD_FAILED: "Something went wrong. Please try again.",
  RESET_PASSWORD_SUCCESSFUL: "Password reset successful!",
  RESET_PASSWORD_FAILED: "Something went wrong. Please try again.",
  // Capsules List
  SENT_CAPSULES_LIST_TITLE: "Sent Time Capsules",
  RECEIVED_CAPSULES_LIST_TITLE: "Received Time Capsules",
  EMPTY_SENT_CAPSULES_LIST_MESSAGE: "You haven't sent any capsules.",
  EMPTY_RECEIVED_CAPSULES_LIST_MESSAGE: "You haven't received any capsules.",

  // Form Validation
  PHONE_INVALID: "Invalid phone number",
  PASSWORD_MIN_LENGTH: "Password must contain at least 8 character(s)",
  PASSWORD_MATCH: "Passwords do not match",
  DATE_INVALID: "Invalid date",
  DATE_FUTURE: "Date must be in the future",

  // Shared
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  EMAIL: "Email Address",
  PHONE: "Phone Number",
  RESET_PASSWORD: "Reset Password",
  CANCEL: "Cancel",
  DELETE: "Delete",
  ACTION_CANNOT_BE_UNDONE: "This action cannot be undone.",
  SEND_DATE: "Send Date",

  // Profile Page
  profile: {
    CHANGE_PASSWORD: "Want to change your Password?",
    SAVE_PROFILE: "Save Profile",
    EDIT_PROFILE: "Edit Profile",
    UPLOAD_PICTURE_DIALOG_TITLE: "Upload a profile picture",
    CHANGE_IMAGE: "Change Image",
    UPDATE_PICTURE: "Update Profile Picture",
    UPLOAD_SUCCESSFUL: "Upload successful!",
    UPLOAD_FAILED: "Upload failed. Please try again.",
    SAVE_SUCCESSFUL: "Profile saved successfully!",
    SAVE_FAILED: "Profile save failed. Please try again.",
  },

  capsule_details: {
    DELETE_CONFIRMATION: "Are you sure you want to delete this capsule?",
    DELETE_CAPSULE: "Delete Capsule",
    SAVE_CAPSULE: "Save Capsule",
    SAVE_SUCCESSFUL: "Capsule saved successfully!",
    SAVE_FAILED: "Capsule save failed. Please try again.",
  },
};
