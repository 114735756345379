import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import "../css/footer.css";
import { FaTwitter, FaFacebook, FaInstagram } from "react-icons/fa";
import { Event } from "../Components/Tracking/Tracking";

const PREFIX = "Footer";

const classes = {
  toolbar: `${PREFIX}-toolbar`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.toolbar}`]: {
    justifyContent: "center",
  },
}));

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleFacebookClick = () => {
    Event("Link", "VTC Facebook", "Footer_Facebook");
    window.open(
      "https://www.facebook.com/Virtual-Time-Capsule-1566184820145798",
      "_blank",
    );
  };
  handleTwitterClick = () => {
    Event("Link", "VTC Twitter", "Footer_Twitter");
    window.open("https://twitter.com/VirtualTimeCaps", "_blank");
  };
  handleInstagramClick = () => {
    Event("Link", "VTC Instagram", "Footer_Instagram");
    window.open("https://www.instagram.com/virtual_time_capsule/", "_blank");
  };
  handleDevTwitterClick = () => {
    Event("Link", "Dev Twitter", "Footer_DevTwitter");
    window.open("https://twitter.com/vtcdevs", "_blank");
  };
  handleFeedbackFormClick = () => {
    Event("Link", "Feedback Form", "Footer_FeedbackForm");
    window.open(
      "https://airtable.com/appvrNpQyi9sZG1Fx/shrr2eRvFfhZdEwPO",
      "_blank",
    );
  };

  render() {
    const {} = this.props;

    return (
      <Root className="footerContainer">
        <ul className="copyright">
          <li>
            <p className="landingText">We'd love your feedback!</p>
          </li>
        </ul>
        <ul className="copyright">
          <li>
            <p className="landingText">
              Fill out the feedback form{" "}
              <a
                className="landingText"
                href=""
                onClick={this.handleFeedbackFormClick}
              >
                here
              </a>
              .
            </p>
            <p className="landingText">
              Your feedback will help us build a better product. Thank you!
            </p>
          </li>
          <li>
            <p className="landingText">
              &copy; Virtual Time Capsule. All rights reserved.
            </p>
          </li>
        </ul>
      </Root>
    );
  }
}

export default Footer;
