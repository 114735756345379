import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

const PREFIX = "AboutSending";

const classes = {
  root: `${PREFIX}-root`,
  paper: `${PREFIX}-paper`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  heading: `${PREFIX}-heading`,
  secondaryHeading: `${PREFIX}-secondaryHeading`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
  expDetails: `${PREFIX}-expDetails`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: "100%",
    TextAlign: "left",
  },

  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },

  [`& .${classes.header}`]: {
    paddingLeft: "2vw",
    color: "#c9c9c9",
    fontSize: "2.5vw",
  },

  [`& .${classes.content}`]: {
    padding: "20px",
  },

  [`& .${classes.heading}`]: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },

  [`& .${classes.secondaryHeading}`]: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },

  [`& .${classes.buttons}`]: {
    display: "flex",
    justifyContent: "flex-end",
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.expDetails}`]: {
    backgroundColor: "#c9c9c9",
  },
}));

//TODO typography header can be its own class that takes props

class AboutSending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: null,
      open: true,
    };
  }

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const {} = this.props;

    return (
      <Root className="home-container">
        <div className="row aln-center">
          <div className="col-12-wide col-10-normal ">
            <Paper className={classes.paper}>
              <List className={classes.root}>
                <div className={classes.content}>
                  <Typography component="h1" variant="h4" align="center">
                    <b>Virtual Time Capsules</b>
                  </Typography>
                  <Typography component="h1" variant="h5" align="left">
                    <p>
                      <b>What they are and how they work</b>
                    </p>
                  </Typography>
                  <Typography variant="subtitle1">
                    <p>
                      A Virtual Time Capsule is exactly what it sounds like, a
                      digital package full of memories throughout time. Think of
                      this as the ability to surprise someone with a special
                      blast from the past or leave a personal message. Simply
                      follow this simple three-step process:
                    </p>
                  </Typography>

                  <Typography component="h1" variant="h6" align="left">
                    <b>1. Create + Upload</b>
                  </Typography>
                  <Typography variant="subtitle1">
                    <p>
                      Upload what you want to send (Due to this being a Beta,
                      you will only be able to upload one video. In the near
                      future, we will open it up to more file types such as
                      multiple videos, picture albums, audio files, and written
                      messages.
                    </p>
                  </Typography>

                  <Typography component="h1" variant="h6" align="left">
                    <b>2. Choose a recipient</b>
                  </Typography>
                  <Typography variant="subtitle1">
                    <p>
                      Choose who you want to send it to and fill out the
                      requested information on the delivery form. We ask for a
                      lot of information and alternate contact methods in order
                      to maximize our chances of being able to get in touch with
                      your recipient years down the line. Addresses, phone
                      numbers, and emails change all the time. It is very likely
                      that one or more of those things will change over the
                      years for the person you want to send a time capsule to.
                      In order to compensate for this we ask for backup contact
                      methods just in case we can't reach your recipient through
                      the primary ones. You can help us out in overcoming this
                      challenge by keeping you and your recipients contact
                      information as up to date as possible.
                    </p>
                  </Typography>
                  <Typography variant="subheading" align="left">
                    <b>
                      What if your recipent does not have the information we
                      need?
                    </b>
                  </Typography>
                  <Typography variant="subtitle1">
                    <p>
                      If you do not have access to your recipient's multiple
                      addresses, we recommend that you either put your address
                      or create an email for them. At that point, it will be
                      your job to make sure that the contact information gets
                      passed along to them when the time comes for them to
                      receive the Time Capsule you sent.
                    </p>
                  </Typography>

                  <Typography component="h1" variant="h6" align="left">
                    <b>3. Pick a date</b>
                  </Typography>
                  <Typography variant="subtitle1">
                    <p>
                      Type in the date that you would like the Time Capsule to
                      be delivered (While in Beta, delivery dates are limited to
                      5 year out).
                    </p>
                    In order to create a nostalgic surprise, the recipient will
                    not have any knowledge of these Time Capsules until the
                    release date.
                    <p></p>
                  </Typography>

                  <Typography component="h1" variant="h6" align="left">
                    <b>Storage</b>
                  </Typography>
                  <Typography variant="subtitle1">
                    <p>
                      All user information and data on our system is fully
                      encrypted and 100% private unless otherwise specified by
                      you. Your data will not be accessible or viewable by
                      anyone other than you. The only data that Virtual Time
                      Capsule will see or use is the recipient's contact
                      information in order to properly deliver your Time
                      Capsules.
                    </p>
                  </Typography>

                  <Typography component="h1" variant="h6" align="left">
                    <b>Delivery</b>
                  </Typography>
                  <Typography variant="subtitle1">
                    <p>
                      Pick a date and time in the future for your recipient to
                      receive a time capsule. Choose a day as soon as a month or
                      as far out as 10 years (While in beta delivery dates are
                      limited to 5 year out). You could even have one delivered
                      every year on someone’s birthday if you wanted to. Once
                      you've created a time capsule, filled out the recipient
                      information, and chosen a date for it to be sent, our
                      system will store your time capsule and automatically send
                      it out on the specified day.
                    </p>
                  </Typography>

                  <Typography component="h1" variant="h6" align="left">
                    <b>Price</b>
                  </Typography>
                  <Typography variant="subtitle1">
                    <p>
                      Pick a date and time in the future for your recipient to
                      receive a time capsule. Choose a day as soon as a month or
                      as far out as 10 years (While in beta delivery dates are
                      limited to 5 year out). You could even have one delivered
                      every year on someone's birthday if you wanted to. Once
                      you've created a time capsule, filled out the recipient
                      information, and choose a date for it to be sent, our
                      system will store your time capsule and automatically send
                      it out on the specified day.{" "}
                    </p>
                  </Typography>
                </div>
              </List>
            </Paper>
          </div>
        </div>
      </Root>
    );
  }
}

AboutSending.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default AboutSending;
