import { TextField } from "@mui/material";
import strings from "../../../common/strings";
import { FieldError, UseFormRegister } from "react-hook-form";
import FormFieldWrapper from "./FormFieldWrapper";

interface EmailFieldProps {
  /**
   * Register function from useForm, used to link the email input to form validation
   */
  register: UseFormRegister<any>;

  /**
   * errors from useForm, used to display any email field errors
   */
  emailError?: FieldError;

  /**
   * Whether the input should be read-only or editable
   */
  isReadOnly?: boolean;

  /**
   * Whether the input will render with a value
   */
  hasDefaultValue?: boolean;
}

const EmailField: React.FC<EmailFieldProps> = (props) => {
  const { register, isReadOnly, emailError, hasDefaultValue } = props;

  const emailErrorMessage = emailError ? (emailError.message as string) : "";

  return (
    <FormFieldWrapper>
      <TextField
        {...register("email")}
        variant="outlined"
        required
        disabled={isReadOnly}
        label={strings.EMAIL}
        fullWidth
        autoComplete="email"
        error={!!emailError}
        helperText={emailErrorMessage}
        InputLabelProps={{ shrink: hasDefaultValue }}
      />
    </FormFieldWrapper>
  );
};

export default EmailField;
