/**
 * Shared types that are not in the database
 */

import { Dayjs } from "dayjs";
import { Database } from "./database.types";

export interface UserFormData {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

/**
 * public.users doesn't have membershipId yet, so this is a temporary structure to include that.
 * Once we've implemented membershipId, we should be able to remove this and just use the user object
 */
export type UserData = Database["public"]["Tables"]["users"]["Row"] & {
  membershipId: string;
};

export interface ChangedUserFields {
  firstName?: boolean;
  lastName?: boolean;
  phoneNumber?: boolean;
  email?: boolean;
}

/**
 * The attachment type saved in supabase
 */
export enum AttachmentType {
  ATTACHMENT = "attachment",
  PREVIEW = "preview",
  THUMBNAIL = "thumbnail",
}

export interface CapsuleFormData {
  firstName: string;
  lastName: string;
  email: string;
  sendDate: Dayjs;
  message: string;
}

export interface Recipient {
  firstName: string;
  lastName: string;
  email: string;
  backupEmail?: string;
  phoneNumber?: string;
  backupPhoneNumber?: string;
}
