import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useCallback,
} from "react";
import { CapsuleFormInfo } from "../Components/CreateNewTimeCapsule/RecordNewTimeCapsule/RecipientForm";
import dayjs from "dayjs";
import { useState } from "react";
import useFileUpload, {
  Upload as FileUpload,
  UploadParam,
} from "../Api/hooks/useFileUpload";
import useThumbnailUpload, {
  Upload as ThumbnailUpload,
} from "../Api/hooks/useThumbnailUpload";
import { v4 as uuidv4 } from "uuid";

const currentDate = dayjs();

interface CreateCapsuleContextType {
  capsuleFormInfo: CapsuleFormInfo;
  setCapsuleFormInfo: Dispatch<SetStateAction<CapsuleFormInfo>>;
  uploadFiles: (files: UploadParam[]) => Promise<void>;
  fileUploads: FileUpload[];
  thumbnailUploads: ThumbnailUpload[];
  setSubmitStatus: (status: "idle" | "submitting" | "error") => void;
  resetAllCapsuleData: () => void;
  resetCapsuleVideoData: () => void;
}

/**
 *
 * NOTE: Change this code when releasing to users -
 two day minimum removed for testing
 Change to:
 export const minCapsuleDate = currentDate.add(2, "day");
 */
// TODO: Make this update! Everyone tests on Tuesday, get results for Thursday?
export const minCapsuleDate = currentDate;

// Context
export const CreateCapsuleContext = createContext<
  CreateCapsuleContextType | undefined
>(undefined);

// Provider
export const CreateCapsuleProvider = ({ children }) => {
  const {
    upload: uploadFiles,
    uploads: fileUploads,
    resetUploads: resetFileUploads,
  } = useFileUpload("capsule_assets");
  const {
    upload: uploadThumbnails,
    uploads: thumbnailUploads,
    resetUploads: resetThumbnailUploads,
  } = useThumbnailUpload();
  const [capsuleFormInfo, setCapsuleFormInfo] = useState<CapsuleFormInfo>({
    submitStatus: "idle",
    recipient: {
      firstName: "",
      lastName: "",
      email: "",
    },
    capsule: {
      id: uuidv4(),
      title: "",
      message: "",
      sendDate: minCapsuleDate.toDate(),
    },
  });

  const upload = useCallback(
    async (files: UploadParam[]) => {
      uploadFiles(files);
      uploadThumbnails(
        files.map((file) => ({
          ...file,
          capsuleId: capsuleFormInfo.capsule.id,
        })),
      );
    },
    [capsuleFormInfo, uploadFiles, uploadThumbnails],
  );

  const setSubmitStatus = (submitStatus: "idle" | "submitting" | "error") => {
    setCapsuleFormInfo((prevInfo) => ({
      ...prevInfo,
      submitStatus,
    }));
  };

  const resetAllCapsuleData = () => {
    setCapsuleFormInfo({
      submitStatus: "idle",
      recipient: {
        firstName: "",
        lastName: "",
        email: "",
      },
      capsule: {
        id: uuidv4(),
        title: "",
        message: "",
        sendDate: minCapsuleDate.toDate(),
      },
    });
    resetCapsuleVideoData();
  };

  const resetCapsuleVideoData = () => {
    resetFileUploads();
    resetThumbnailUploads();
  };

  return (
    <CreateCapsuleContext.Provider
      value={{
        capsuleFormInfo,
        setCapsuleFormInfo,
        uploadFiles: upload,
        fileUploads,
        thumbnailUploads,
        setSubmitStatus,
        resetAllCapsuleData,
        resetCapsuleVideoData,
      }}
    >
      {children}
    </CreateCapsuleContext.Provider>
  );
};

// Hook
export function useCreateCapsule(): CreateCapsuleContextType {
  const context = useContext(CreateCapsuleContext);
  if (!context) {
    throw new Error(
      "useCreateCapsule must be used within a CreateCapsuleProvider",
    );
  }
  return context;
}
