import { Button, TextField, styled } from "@mui/material";
import { CapsuleFormSchema } from "../../../utils/validation";
import strings from "../../../common/strings";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import api from "../../../Api";
import NameFields from "./NameFields";
import DateField from "./DateField";
import toast from "react-hot-toast";
import { Capsule } from "../../../Api/capsules";
import dayjs from "dayjs";
import { Contact } from "../../../Api/contacts";
import FormFieldWrapper from "./FormFieldWrapper";
import ButtonWrapper from "../ButtonWrapper";
import { PostgrestError } from "@supabase/supabase-js";
import * as Sentry from "@sentry/react";
import EmailField from "./EmailField";

const CapsuleFormRoot = styled("form", {
  name: "CapsuleForm",
  slot: "root",
})(({ theme }) => ({
  paddingTop: theme.spacing(1),
  minWidth: theme.spacing(30),
}));

interface CapsuleFormProps {
  /**
   * The selected capsule
   */
  capsule: Capsule;

  /**
   * The recipient of this capsule
   */
  recipient: Contact;

  /**
   * Function to call when pressing cancel
   */
  onCancel: Function;

  /**
   * Function to call after submitting form
   */
  afterSubmit: Function;
}

/**
 * Creates a form to display and capture capsule recipient information
 */
const CapsuleForm: React.FC<CapsuleFormProps> = (props) => {
  const { capsule, onCancel, recipient, afterSubmit } = props;
  const { message, sendDate } = capsule;
  const { firstName, lastName, userId, user, contactId } = recipient;
  const { email } = user;

  const {
    register,
    formState: { errors, dirtyFields, isDirty, isValid },
    getValues,
    handleSubmit,
    control,
  } = useForm({
    resolver: zodResolver(CapsuleFormSchema),
    defaultValues: {
      firstName: firstName,
      lastName: lastName,
      email: email,
      message: message,
      sendDate: dayjs(sendDate),
    },
    mode: "onBlur",
  });

  const updateValues = async () => {
    const values = getValues();
    const errors: PostgrestError[] = [];
    let recipientId = contactId;
    const { id: capsuleId } = capsule;

    if (dirtyFields.email) {
      // if email is changed, have to treat this as a new contact, since email is pulled directly from users
      const { firstName, lastName, email } = values;
      if (firstName && lastName && email) {
        const { data: newRecipientId, error } =
          await api.contacts.createContact(
            { firstName, lastName, email },
            userId,
          );
        if (error) {
          errors.push(error);
        }
        if (newRecipientId) {
          recipientId = newRecipientId;

          const error = await api.capsules.updateCapsuleRecipient(
            capsuleId,
            recipientId,
            contactId,
          );
          if (error) {
            errors.push(error);
          }
        }
      }
    } else {
      // If email isn't changed, treat this as just a contact edit
      if (dirtyFields.firstName || dirtyFields.lastName) {
        const error = await api.contacts.updateContact(
          recipient,
          values.firstName,
          values.lastName,
        );

        if (error) {
          errors.push(error);
        }
      }
    }

    if (dirtyFields.message || dirtyFields.sendDate || dirtyFields.email) {
      const error = await api.capsules.updateCapsule(
        capsuleId,
        values.message,
        values.sendDate.toISOString(),
        recipientId,
      );

      if (error) {
        errors.push(error);
      }
    }

    if (errors.length > 0) {
      toast.error(strings.capsule_details.SAVE_FAILED);
      errors.forEach((error) => {
        Sentry.captureException(error);
      });
    } else {
      afterSubmit(values, recipientId);
      toast.success(strings.capsule_details.SAVE_SUCCESSFUL);
    }
  };

  const onSubmit = () => {
    if (isDirty) {
      updateValues();
    }
    onCancel();
  };

  return (
    <CapsuleFormRoot onSubmit={handleSubmit(onSubmit)}>
      <NameFields register={register} errors={errors} />
      <EmailField register={register} emailError={errors.email} />
      <FormFieldWrapper>
        <TextField
          {...register("message")}
          variant="outlined"
          required
          label="Message"
          fullWidth
        />
      </FormFieldWrapper>
      <DateField control={control} dateError={errors.sendDate} />
      <ButtonWrapper>
        <Button
          variant="contained"
          color="secondary"
          className="cancel"
          onClick={() => {
            onCancel();
          }}
        >
          {strings.CANCEL}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="submit"
          disabled={!isValid}
        >
          {strings.capsule_details.SAVE_CAPSULE}
        </Button>
      </ButtonWrapper>
    </CapsuleFormRoot>
  );
};

export default CapsuleForm;
