import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import classNames from "classnames";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import BlogCard from "./BlogCard";

const PREFIX = "BlogLayout";

const classes = {
  appBar: `${PREFIX}-appBar`,
  icon: `${PREFIX}-icon`,
  heroUnit: `${PREFIX}-heroUnit`,
  heroContent: `${PREFIX}-heroContent`,
  layout: `${PREFIX}-layout`,
  cardGrid: `${PREFIX}-cardGrid`,
  card: `${PREFIX}-card`,
  cardMedia: `${PREFIX}-cardMedia`,
  cardContent: `${PREFIX}-cardContent`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")(({ theme }) => ({
  [`& .${classes.appBar}`]: {
    position: "relative",
  },

  [`& .${classes.icon}`]: {
    marginRight: theme.spacing(2),
  },

  [`& .${classes.heroUnit}`]: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: 1400,
    margin: "auto",
  },

  [`& .${classes.heroContent}`]: {
    maxWidth: 600,
    margin: "0 auto",
    padding: `${theme.spacing(8)} 0 ${theme.spacing(6)}`,
  },

  [`& .${classes.layout}`]: {
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      width: 1400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  [`& .${classes.cardGrid}`]: {
    padding: `${theme.spacing(8)} 0`,
  },

  [`& .${classes.card}`]: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.cardMedia}`]: {
    paddingTop: "56.25%", // 16:9
  },

  [`& .${classes.cardContent}`]: {
    flexGrow: 1,
  },
}));

const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

function BlogLayout(props) {
  const {} = props;

  return (
    <Root>
      <CssBaseline />

      <main>
        {/* Hero unit */}
        <div className={classes.heroUnit}>
          <div className={classes.heroContent}>
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              Virtual Time Capsule Blog
            </Typography>
            <Typography
              variant="h6"
              align="center"
              color="textSecondary"
              paragraph
            >
              Welcome to the Virtual Time Capsule Blog...
            </Typography>
          </div>
        </div>
        <div className={classNames(classes.layout, classes.cardGrid)}>
          {/* End hero unit */}
          <Grid container spacing={40}>
            {cards.map((card) => (
              <Grid item key={card} sm={6} md={4} lg={3}>
                <BlogCard />
              </Grid>
            ))}
          </Grid>
        </div>
      </main>
    </Root>
  );
}

BlogLayout.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default BlogLayout;
