import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { EmailSchema } from "../../../utils/validation";
import { minCapsuleDate } from "../../../contexts/CreateCapsuleContext";

const PREFIX = "RecipientForm";

const classes = {
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
  form: `${PREFIX}-form`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.buttons}`]: {
    display: "flex",
    justifyContent: "flex-end",
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.form}`]: {
    width: "100%",
    marginTop: "16px",
  },
}));

export type CapsuleFormInfo = {
  file?: File;
  submitStatus: "idle" | "submitting" | "error";
  recipient: {
    firstName: string;
    lastName: string;
    email: string;
  };
  capsule: {
    id: string;
    title: string;
    message: string;
    sendDate: Date;
  };
};

const RecipientForm = ({
  capsuleFormInfo,
  setCapsuleFormInfo,
  handleBack,
  handleContinue,
}) => {
  const currentDate = dayjs();
  const maxDate = currentDate.add(5, "year").month(11).date(31);
  const minFormat = dayjs(minCapsuleDate).format("YYYY-MM-DD");
  const maxFormat = dayjs(maxDate).format("YYYY-MM-DD");
  const [isEmailFieldDirty, setIsEmailFieldDirty] = useState(false);

  const handleRecipientInputChange = (e) => {
    const { name, value } = e.target;
    setCapsuleFormInfo((prevInfo) => ({
      ...prevInfo,
      recipient: {
        ...prevInfo.recipient,
        [name]: value,
      },
    }));
  };

  const handleCapsuleInputChange = (e) => {
    const { name, value } = e.target;
    setCapsuleFormInfo((prevInfo) => ({
      ...prevInfo,
      capsule: {
        ...prevInfo.capsule,
        [name]: value,
      },
    }));
  };

  const handleCapsuleDateChange = (date) => {
    setCapsuleFormInfo((prevInfo) => ({
      ...prevInfo,
      capsule: {
        ...prevInfo.capsule,
        sendDate: dayjs(date).toDate(),
      },
    }));
  };

  const onBack = () => {
    handleBack();
  };

  const onContinue = () => {
    handleContinue(capsuleFormInfo);
  };

  function isValidEmail(email) {
    try {
      EmailSchema.parse(email);
      return true;
    } catch (error) {
      return false;
    }
  }

  function isFormComplete(
    capsuleFormInfo: CapsuleFormInfo,
    minFormat: string,
    maxFormat: string,
  ): boolean {
    const formattedDate = dayjs(capsuleFormInfo.capsule.sendDate).format(
      "YYYY-MM-DD",
    );

    return (
      capsuleFormInfo.recipient.firstName !== "" &&
      capsuleFormInfo.recipient.lastName !== "" &&
      isValidEmail(capsuleFormInfo.recipient.email) &&
      capsuleFormInfo.capsule.title !== "" &&
      capsuleFormInfo.capsule.message !== "" &&
      formattedDate >= minFormat &&
      formattedDate <= maxFormat
    );
  }

  function shouldDisableNextButton(
    capsuleFormInfo: CapsuleFormInfo,
    minFormat: string,
    maxFormat: string,
  ): boolean {
    return !isFormComplete(capsuleFormInfo, minFormat, maxFormat);
  }

  return (
    <Root>
      <Typography variant="h6" gutterBottom>
        Recipient Information
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            required
            id="firstName"
            name="firstName"
            label="First Name"
            fullWidth
            autoComplete="given-name"
            onChange={handleRecipientInputChange}
            value={capsuleFormInfo.recipient.firstName}
          ></TextField>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            required
            id="lastName"
            name="lastName"
            label="Last Name"
            fullWidth
            autoComplete="family-name"
            onChange={handleRecipientInputChange}
            value={capsuleFormInfo.recipient.lastName}
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            required
            id="email"
            name="email"
            label="Email Address"
            fullWidth
            autoComplete="email"
            onChange={handleRecipientInputChange}
            onBlur={() => setIsEmailFieldDirty(true)}
            value={capsuleFormInfo.recipient.email}
            error={
              isEmailFieldDirty &&
              !isValidEmail(capsuleFormInfo.recipient.email)
            }
            helperText={
              !isValidEmail(capsuleFormInfo.recipient.email)
                ? "Please enter a valid email"
                : ""
            }
          ></TextField>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            required
            id="title"
            name="title"
            label="Time Capsule Title"
            fullWidth
            autoComplete="capsule-title"
            onChange={handleCapsuleInputChange}
            value={capsuleFormInfo.capsule.title}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            required
            id="message"
            name="message"
            label="Message"
            fullWidth
            autoComplete="capsule-message"
            onChange={handleCapsuleInputChange}
            value={capsuleFormInfo.capsule.message}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ marginTop: "25px" }}>
            <DatePicker
              value={dayjs(capsuleFormInfo.capsule.sendDate)}
              minDate={dayjs(minCapsuleDate)}
              maxDate={dayjs(maxDate)}
              label="Send Date"
              onChange={handleCapsuleDateChange}
            ></DatePicker>
          </div>
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        <Button
          id="back"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          id="next"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onContinue}
          disabled={shouldDisableNextButton(
            capsuleFormInfo,
            minFormat,
            maxFormat,
          )}
        >
          Next
        </Button>
      </div>
    </Root>
  );
};

export default RecipientForm;
