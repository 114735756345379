import GIF from "gif.js.optimized";
import playButtonImg from "../Assets/images/play-button.png";
import { getDefaultFileName } from "../utils/file";

interface ThumbnailFrame {
  data: ImageData;
  delay: number;
}

interface ThumbnailOptions {
  debug?: boolean;
}

const MAX_THUMBNAIL_SIZE = 320;
const PLAY_BUTTON_SIZE = 80;

export function getThumbnailDimensions(width: number, height: number) {
  const ratio = width / height;
  const isPortrait = ratio < 1;
  if (isPortrait) {
    const thumbnailHeight = MAX_THUMBNAIL_SIZE;
    const thumbnailWidth = thumbnailHeight * ratio;
    return { width: thumbnailWidth, height: thumbnailHeight };
  }

  const thumbnailWidth = MAX_THUMBNAIL_SIZE;
  const thumbnailHeight = thumbnailWidth / ratio;
  return { width: thumbnailWidth, height: thumbnailHeight };
}

export async function generateThumbnails(
  file,
  { debug = false }: ThumbnailOptions = {},
): Promise<{
  gifPreviewBlob: Blob;
  dimensions: { width: number; height: number };
  imageThumbnailBlob?: Blob;
}> {
  let video = document.createElement("video");
  video.muted = true;
  const canvas = document.getElementById(
    "thumbnailCanvas",
  ) as HTMLCanvasElement | null;
  if (!canvas) {
    throw new Error("Unable to find canvas element");
  }

  const ctx = canvas.getContext("2d", {
    willReadFrequently: true,
  }) as CanvasRenderingContext2D | null;
  if (!ctx) {
    throw new Error("Unable to get canvas context");
  }

  const frames: ThumbnailFrame[] = [];
  let thumbnailData: ImageData;
  const videoObjectUrl = URL.createObjectURL(file);
  debug &&
    console.log(`GIF: ${Date.now()} - Video object url: ${videoObjectUrl}`);

  video.currentTime = 0;
  video.controls = false;
  video.style.display = "none";
  video.autoplay = false;
  video.setAttribute("webkit-playsinline", "webkit-playsinline");
  video.setAttribute("playsinline", "playsinline");

  const playButtonImage = new Image();
  playButtonImage.src = playButtonImg;

  return new Promise(async (resolve, reject) => {
    await new Promise((resolve) => (playButtonImage.onload = resolve));
    debug && console.log(`GIF: ${Date.now()} - Play button image loaded`);

    try {
      video.addEventListener("loadedmetadata", () => {
        debug && console.log(`GIF: ${Date.now()} - Video metadata loaded`);
        const thumbnailDimensions = getThumbnailDimensions(
          video.videoWidth,
          video.videoHeight,
        );
        canvas.width = thumbnailDimensions.width;
        canvas.height = thumbnailDimensions.height;
        debug &&
          console.log(
            `GIF: ${Date.now()} - Canvas dimensions: ${canvas.width}x${
              canvas.height
            }`,
          );

        debug && console.log(`GIF: ${Date.now()} - Start capturing frames...`);
        const captureInterval = setInterval(() => {
          if (video.currentTime >= 10 || video.currentTime >= video.duration) {
            clearInterval(captureInterval);

            const gif = new GIF({
              workers: 10,
              quality: 2,
              workerScript: `${process.env.PUBLIC_URL}/gif.worker.js`,
              width: canvas.width,
              height: canvas.height,
            });

            frames.forEach((frame) => {
              gif.addFrame(frame.data, { delay: frame.delay, copy: true });
            });

            gif.on("finished", async function (gifPreviewBlob) {
              debug &&
                console.log(`GIF: ${Date.now()} - Finished rendering gif`, {
                  gifPreviewBlob,
                  problemKeys: {
                    // @ts-ignore
                    filename:
                      gifPreviewBlob &&
                      (gifPreviewBlob?.name ||
                        getDefaultFileName(gifPreviewBlob!.type)),
                    contentType: gifPreviewBlob?.type,
                    byteSize: gifPreviewBlob?.size,
                  },
                });

              const imageThumbnailBlob = await createThumbnail(thumbnailData);
              resolve({
                gifPreviewBlob,
                imageThumbnailBlob: imageThumbnailBlob
                  ? imageThumbnailBlob
                  : undefined,
                dimensions: {
                  width: canvas.width,
                  height: canvas.height,
                },
              });
            });

            debug && console.log(`GIF: ${Date.now()} - Start rendering gif`);
            gif.render();
          } else {
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            // Draw play button on top of thumbnail
            ctx.drawImage(
              playButtonImage,
              canvas.width / 2 - PLAY_BUTTON_SIZE / 2,
              canvas.height / 2 - PLAY_BUTTON_SIZE / 2,
              PLAY_BUTTON_SIZE,
              PLAY_BUTTON_SIZE,
            );
            const imageData = ctx.getImageData(
              0,
              0,
              canvas.width,
              canvas.height,
            );

            // Use first frame to make thumbnail image
            if (frames.length === 0) {
              thumbnailData = imageData;
            }
            frames.push({ data: imageData, delay: 100 });
          }
        }, 100);
      });

      debug && console.log(`GIF: ${Date.now()} - Start playing video`);

      video.src = videoObjectUrl;
      await video.play();
    } catch (error) {
      debug && console.log(`GIF: ${Date.now()} - Error: ${error}`);
      reject(error);
    }
  });
}

/**
 * Creates the thumbnail image from the first frame of the gif
 * @param imageData Data from the first frame of the gif
 * @returns the Blob of the thumbnail image, or null if one couldn't be created
 */
const createThumbnail = async (imageData: ImageData): Promise<Blob | null> => {
  const { width, height } = imageData;

  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext("2d");
  if (ctx) {
    ctx.putImageData(imageData, 0, 0);

    const blob = await new Promise((resolve) => canvas.toBlob(resolve));
    return blob instanceof Blob ? blob : null;
  }

  return null;
};
