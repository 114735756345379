import { SupabaseService } from "./service";
import { Database } from "../types/database.types";
import { AppResponse } from "../types/request";

type Theme = Database["public"]["Tables"]["themes"]["Row"];

export type Category = Database["public"]["Tables"]["theme_categories"]["Row"];

export type Prompt = Database["public"]["Tables"]["prompts"]["Row"];

export type GetThemeResponseItem = Theme & {
  hasCategories: boolean;
  categories: (Category & { prompts: Prompt[] })[];
  prompts: Prompt[];
};

export default class ThemesService extends SupabaseService {
  getThemes = async (): AppResponse<GetThemeResponseItem[]> => {
    const { data, error } = await this.supabase
      .from("themes")
      .select("*, categories:theme_categories(*, prompts(*)), prompts(*)")
      .is("prompts.categoryId", null);

    if (error) {
      return { error, data: null };
    }

    return {
      data: (data
        ? data.map((theme) => ({
            ...theme,
            hasCategories: theme.categories.length > 0,
          }))
        : []) as GetThemeResponseItem[],
      error: null,
    };
  };
}
