import { Box, Button, Grid, Paper, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import api from "../Api";
import { UserData } from "../types/types";
import ProfilePictureUpdate from "../Components/Profile/ProfilePictureUpdate";
import UserForm from "../Components/Profile/UserForm";
import strings from "../common/strings";

const classes = {
  wrapper: "profileWrapper",
  pictureWrapper: "profilePictureWrapper",
  pictureUpdate: "profilePictureUpdate",
  changePassword: "changePassword",
};
const ProfileRoot = styled("div", {
  name: "Profile",
  slot: "root",
})(({ theme }) => ({
  padding: theme.spacing(2, 4),

  [`.${classes.wrapper}`]: {
    padding: theme.spacing(1),

    [`.${classes.pictureWrapper}`]: {
      margin: theme.spacing(2) + " auto 0",

      [`.${classes.pictureUpdate}`]: {
        display: "block",
        padding: theme.spacing(0.5),
      },
    },
  },

  [`.${classes.changePassword}`]: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1),
  },
}));

/**
 * Base component for the profiile page
 */
const Profile: React.FC = () => {
  const [user, setUser] = useState<UserData>();

  const fetchUser = async () => {
    const { user, error } = await api.getUser();
    if (error) {
      throw new Error(error.message);
    }
    setUser(user);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <ProfileRoot>
      <Typography component="h1" variant="h4">
        Profile
      </Typography>
      {user && (
        <Paper className={classes.wrapper} elevation={2}>
          <Grid container spacing={2}>
            <Grid item xs="auto" className={classes.pictureWrapper}>
              <ProfilePictureUpdate
                authId={user.authId ? user.authId : undefined}
                avatarPath={user.avatarPath ? user.avatarPath : undefined}
              />
            </Grid>
            <Grid item xs>
              <UserForm user={user} />
            </Grid>
          </Grid>
        </Paper>
      )}
      <Box className={classes.changePassword}>
        <Typography color="GrayText">
          {strings.profile.CHANGE_PASSWORD}
        </Typography>
        <Button color="primary" href="/forgot-password">
          {strings.RESET_PASSWORD}
        </Button>
      </Box>
    </ProfileRoot>
  );
};

export default Profile;
