import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import StepperHeader from "../../TimeCapsuleForm/StepperHeader";
import ChooseCapsulePrompt from "./ChooseCapsulePrompt";
import RecordCapsuleVideo from "./RecordCapsuleVideo";
import { Prompt } from "../../../Api/themes";
import RecipientForm, { CapsuleFormInfo } from "./RecipientForm";
import PaymentDetails from "./PaymentDetails";
import { useCreateCapsule } from "../../../contexts/CreateCapsuleContext";
import SendYourCapsule from "./SendYourCapsule";

enum RecordCapsuleStep {
  ChoosePrompt = 0,
  RecordVideo,
  RecipientInfo,
  // PaymentDetails,
  SendYourCapsule,
}

const steps = [
  "Choose a Prompt",
  "Record Video",
  "Recipient Information",
  // "Payment Details",
  "Send Your Capsule",
];

const RecordNewTimeCapsule = ({ handleCancel, handleSendCapsule }: any) => {
  const [activeStep, setActiveStep] = useState(RecordCapsuleStep.ChoosePrompt);
  const [chosenPrompt, setChosenPrompt] = useState<Prompt | null>(null);
  const { capsuleFormInfo, setCapsuleFormInfo, uploadFiles } =
    useCreateCapsule();
  const { fileUploads, thumbnailUploads } = useCreateCapsule();
  const { resetCapsuleVideoData } = useCreateCapsule();

  function handlePromptSelected(prompt: Prompt) {
    setActiveStep(RecordCapsuleStep.RecordVideo);
    setChosenPrompt(prompt);
    setCapsuleFormInfo((prevState) => {
      return {
        ...prevState,
        capsule: {
          ...prevState.capsule,
          title: prompt.name as string,
        },
      };
    });
  }

  function handleRecordVideoBack() {
    // TODO: Show Modal asking if the user is sure, video will be deleted.
    // TODO: If yes, also add resetCapsuleVideoData() call.
    setActiveStep(RecordCapsuleStep.ChoosePrompt);
    setChosenPrompt(null);
  }

  function handleRecordContinue(videoBlob) {
    setActiveStep(RecordCapsuleStep.RecipientInfo);
    uploadFiles([{ blob: videoBlob }]);
  }

  function handleRecipientInfoBack() {
    setActiveStep(RecordCapsuleStep.RecordVideo);
    // TODO: Set videoBlob playback of video recorder component to previously recorded video.
  }

  function handleRecipientInfoContinue(capsuleFormInfo: CapsuleFormInfo) {
    setActiveStep(RecordCapsuleStep.SendYourCapsule);
    setCapsuleFormInfo((prevState) => {
      return {
        ...prevState,
        ...capsuleFormInfo,
      };
    });
  }

  // function handlePaymentBack() {
  //   setActiveStep(RecordCapsuleStep.RecipientInfo);
  // }

  // function handlePaymentContinue() {
  //   setActiveStep(RecordCapsuleStep.SendYourCapsule);
  // }

  function handleSendCapsuleBack() {
    setActiveStep(RecordCapsuleStep.RecipientInfo);
  }

  return (
    <div>
      <StepperHeader activeStep={activeStep} steps={steps}></StepperHeader>

      {activeStep === RecordCapsuleStep.ChoosePrompt && (
        // @ts-ignore
        <ChooseCapsulePrompt
          handleCancel={handleCancel}
          onPromptSelected={handlePromptSelected}
        ></ChooseCapsulePrompt>
      )}
      {activeStep === RecordCapsuleStep.RecordVideo &&
        chosenPrompt !== null && (
          // @ts-ignore
          <RecordCapsuleVideo
            prompt={chosenPrompt}
            handleBack={handleRecordVideoBack}
            handleContinue={handleRecordContinue}
            handleClearVideo={resetCapsuleVideoData}
            handleStartRecording={resetCapsuleVideoData}
          ></RecordCapsuleVideo>
        )}
      {activeStep === RecordCapsuleStep.RecipientInfo &&
        chosenPrompt !== null && (
          <RecipientForm
            capsuleFormInfo={capsuleFormInfo}
            setCapsuleFormInfo={setCapsuleFormInfo}
            handleBack={handleRecipientInfoBack}
            handleContinue={handleRecipientInfoContinue}
          ></RecipientForm>
        )}
      {/* {activeStep === RecordCapsuleStep.PaymentDetails && (
        <PaymentDetails
          handleBack={handlePaymentBack}
          handleContinue={handlePaymentContinue}
        ></PaymentDetails>
      )} */}
      {activeStep === RecordCapsuleStep.SendYourCapsule && (
        <SendYourCapsule
          handleBack={handleSendCapsuleBack}
          onSubmit={handleSendCapsule}
          shouldDisableSend={
            capsuleFormInfo.submitStatus === "submitting" ||
            fileUploads[0].status !== "success" ||
            thumbnailUploads[0].status !== "success"
          }
        ></SendYourCapsule>
      )}
    </div>
  );
};

export default RecordNewTimeCapsule;
