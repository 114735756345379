import React, { useCallback, useState } from "react";
import { styled } from "@mui/material/styles";
import RecordNewTimeCapsule from "./RecordNewTimeCapsule/RecordNewTimeCapsule";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import toast from "react-hot-toast";
import { useCreateCapsule } from "../../contexts/CreateCapsuleContext";
import { useApi } from "../../Api";
import UploadNewTimeCapsule from "./UploadNewTimeCapsule/UploadNewTimeCapsule.tsx";
import { Upload as FileUpload } from "../../Api/hooks/useFileUpload";
import {
  Dimensions,
  Upload as ThumbnailUpload,
} from "../../Api/hooks/useThumbnailUpload";
import { CreateCapsuleAttachmentBlob } from "../../utils/validation";
import { getDefaultFileName } from "../../utils/file";
import { AttachmentType } from "../../types/types.ts";

const PREFIX = "CreateNewTimeCapsule";

const classes = {
  paper: `${PREFIX}-paper`,
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
  },

  [`& .${classes.buttons}`]: {
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

enum CreateCapsuleFlowState {
  None = 0,
  Record,
  Upload,
}

const getCapsuleAttachmentsFromUpload = (
  uploads: FileUpload[],
): CreateCapsuleAttachmentBlob[] => {
  return uploads.map((upload, index) => ({
    key: upload.objectKey,
    filename:
      // @ts-ignore
      upload.metadata.blob!.name ||
      getDefaultFileName(upload.metadata.blob!.type),
    contentType: upload.metadata.blob!.type,
    metadata: upload.metadata?.dimensions
      ? {
          ...upload.metadata.dimensions,
        }
      : undefined,
    service: "amazon",
    type: AttachmentType.ATTACHMENT,
    byteSize: upload.metadata.blob!.size,
    order: index,
  }));
};

/**
 * Gets the preview and thumbnail of an uploaded thumbnail in attachment blob form
 * @param thumbnail The uploaded thumbnail
 * @returns The gif preview and thumbnail image as attachment blobs
 */
const getCapsuleThumbnailsFromUpload = (
  thumbnail: ThumbnailUpload,
): {
  gifPreview?: CreateCapsuleAttachmentBlob;
  imageThumbnail?: CreateCapsuleAttachmentBlob;
} => {
  const { metadata, gifPreviewPath, imageThumbnailPath } = thumbnail;
  const { dimensions, gifPreviewBlob, imageThumbnailBlob } = metadata;
  return {
    gifPreview: gifPreviewBlob
      ? getThumbnailAttachmentBlob(
          gifPreviewBlob,
          gifPreviewPath,
          AttachmentType.PREVIEW,
          dimensions,
        )
      : undefined,
    imageThumbnail: imageThumbnailBlob
      ? getThumbnailAttachmentBlob(
          imageThumbnailBlob,
          imageThumbnailPath,
          AttachmentType.THUMBNAIL,
          dimensions,
        )
      : undefined,
  };
};

/**
 * Create the attachment blob for an uploaded thumbnail
 * @param blob The thumbnail blob
 * @param path The path the thumbnail was stored
 * @param attachmentType The type of the thumbnail
 * @param dimensions The dimensions of the thumbnail
 * @returns The attachment blob in CreateCapsuleAttachmentBlob form
 */
const getThumbnailAttachmentBlob = (
  blob: Blob,
  path: string,
  attachmentType: AttachmentType,
  dimensions?: Dimensions,
): CreateCapsuleAttachmentBlob => {
  const { size, type } = blob;
  return {
    key: path,
    filename: getDefaultFileName(type),
    contentType: type,
    metadata: dimensions
      ? {
          ...dimensions,
        }
      : undefined,
    service: "supabase",
    type: attachmentType,
    byteSize: size,
  };
};

const CreateNewTimeCapsule = ({ refreshSentCapsulesList }) => {
  const api = useApi();
  const {
    capsuleFormInfo,
    fileUploads,
    thumbnailUploads,
    setSubmitStatus,
    resetAllCapsuleData,
  } = useCreateCapsule();
  const [createCapsuleFlowState, setCreateCapsuleFlowState] = useState(
    CreateCapsuleFlowState.None,
  );

  function activateRecordFlow() {
    setCreateCapsuleFlowState(CreateCapsuleFlowState.Record);
  }

  function activateUploadFlow() {
    setCreateCapsuleFlowState(CreateCapsuleFlowState.Upload);
  }

  function handleCancel() {
    setCreateCapsuleFlowState(CreateCapsuleFlowState.None);
    resetAllCapsuleData();
  }

  const handleSendCapsule = useCallback(async () => {
    if (!capsuleFormInfo) {
      return;
    }

    setSubmitStatus("submitting");
    const loadingToastId = toast.loading("Digging Your Capsule…");

    const { gifPreview, imageThumbnail } = getCapsuleThumbnailsFromUpload(
      thumbnailUploads[0],
    );
    if (!gifPreview || !imageThumbnail) {
      toast.dismiss(loadingToastId);
      toast.error("Uh oh! Sorry, something went wrong");
      setSubmitStatus("error");
      return;
    }

    const payload = {
      capsule: {
        id: capsuleFormInfo.capsule.id,
        title: capsuleFormInfo.capsule.title,
        message: capsuleFormInfo.capsule.message,
        attachments: getCapsuleAttachmentsFromUpload(fileUploads),
        gifPreview: gifPreview,
        imageThumbnail: imageThumbnail,
        sendDate: capsuleFormInfo.capsule.sendDate,
      },
      recipient: {
        firstName: capsuleFormInfo.recipient.firstName,
        lastName: capsuleFormInfo.recipient.lastName,
        email: capsuleFormInfo.recipient.email,
      },
    };

    const error = await api.capsules.createCapsule(payload);
    toast.dismiss(loadingToastId);
    if (error) {
      toast.error("Uh oh! Sorry, something went wrong");
      setSubmitStatus("error");
      return;
    }

    toast.success("Hooray! Your Time Capsule Has Been Sent 😎");
    setCreateCapsuleFlowState(CreateCapsuleFlowState.None);
    refreshSentCapsulesList();
    resetAllCapsuleData();
  }, [api, capsuleFormInfo, fileUploads, thumbnailUploads]);

  return (
    <Root>
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" align="center">
          {createCapsuleFlowState === CreateCapsuleFlowState.None && (
            <span>Create a New Time Capsule</span>
          )}
          {createCapsuleFlowState === CreateCapsuleFlowState.Record && (
            <span>Record a New Time Capsule</span>
          )}
          {createCapsuleFlowState === CreateCapsuleFlowState.Upload && (
            <span>Upload a New Time Capsule</span>
          )}
        </Typography>
        {createCapsuleFlowState === CreateCapsuleFlowState.None && (
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              onClick={activateRecordFlow}
            >
              Record New Capsule
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              className={classes.button}
              onClick={activateUploadFlow}
            >
              Upload New Capsule
            </Button>
          </div>
        )}

        {createCapsuleFlowState === CreateCapsuleFlowState.Record && (
          <RecordNewTimeCapsule
            handleCancel={handleCancel}
            handleSendCapsule={handleSendCapsule}
          />
        )}

        {createCapsuleFlowState === CreateCapsuleFlowState.Upload && (
          <UploadNewTimeCapsule
            handleCancel={handleCancel}
            handleSendCapsule={handleSendCapsule}
          />
        )}
        {/* thumbnailCanvas: used to generate GIF thumbnails */}
        <canvas id="thumbnailCanvas" style={{ display: "none" }} />
      </Paper>
    </Root>
  );
};

export default CreateNewTimeCapsule;
