import React, { Component } from "react";
import "../../App.css";

class Blog extends Component {
  render() {
    return <div className="App">Blogview</div>;
  }
}

export default Blog;
