import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { GetThemeResponseItem } from "../../../Api/themes";
import ThemePanel from "./ThemePanel/ThemesPanel";
import { useApi } from "../../../Api";

const PREFIX = "ChooseCapsulePrompt";

const classes = {
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.buttons}`]: {
    display: "flex",
    justifyContent: "flex-end",
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

interface ChooseCapsulePromptProps {
  classes: any;
  handleCancel: any;
  onPromptSelected: any;
}

const ChooseCapsulePrompt = ({
  handleCancel,
  onPromptSelected,
}: ChooseCapsulePromptProps) => {
  const api = useApi();
  const [themesData, setThemesData] = useState<GetThemeResponseItem[]>([]);

  const setFetchedData = useCallback(async () => {
    const { data: themes, error: getThemesError } =
      await api.themes.getThemes();

    if (getThemesError) {
      // to-do: error handling
      return;
    }

    setThemesData(themes);
  }, [api, setThemesData]);

  useEffect(() => {
    setFetchedData();
  }, []);

  const onCancel = () => {
    handleCancel();
  };

  return (
    <Root>
      {themesData.length === 0 && (
        // todo: add style to center horizontally in containing view
        <CircularProgress></CircularProgress>
      )}
      {/* @ts-ignore */}
      {themesData && (
        <ThemePanel
          themes={themesData}
          onPromptSelected={onPromptSelected}
        ></ThemePanel>
      )}

      <div className={classes.buttons}>
        <Button
          id="cancel"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </Root>
  );
};

// @ts-ignore
export default ChooseCapsulePrompt;
