import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useCallback,
} from "react";
import { useApi } from "../index";
import { Session } from "@supabase/supabase-js";

interface AuthContextProps {
  session: Session | null;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextProps>({
  session: null,
  logout: () => {},
});

export const AuthProvider = ({ children, initialSession }) => {
  const [session, setSession] = useState<Session | null>(initialSession);
  const api = useApi();

  const initializeSession = useCallback(async () => {
    const { data } = await api.getClient().auth.getSession();
    setSession(data.session);
  }, [setSession]);

  const logout = useCallback(async () => {
    await api.getClient().auth.signOut();
  }, []);

  useEffect(() => {
    initializeSession();
    const { data: authListener } = api
      .getClient()
      .auth.onAuthStateChange(async (event, _session) => {
        setSession(_session);
      });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ session, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
