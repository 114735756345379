import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Prompt } from "../../../Api/themes";
import { Button } from "@mui/material";
import VideoRecorder from "react-video-recorder";
import VideoRecorderActions from "./VideoRecorder/VideoRecorderActions";
import VideoRecorderDisconnectedView from "./VideoRecorder/VideoRecorderDisconnectedView";
import { useRecordedVideo } from "../../../contexts/RecordedVideoContext";

const VIDEO_RECORDER_TIME_LIMIT = 120000; //time in milliseconds
const PREFIX = "RecordCapsuleVideo";

const classes = {
  buttons: `${PREFIX}-buttons`,
  button: `${PREFIX}-button`,
  videoRecorder: `${PREFIX}-videoRecorder`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.buttons}`]: {
    display: "flex",
    justifyContent: "flex-end",
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.videoRecorder}`]: {
    minWidth: "320px",
    margin: "0 auto",
    aspectRatio: "4 / 3",
    maxWidth: "640px",
    [theme.breakpoints.up("xl")]: {
      maxWidth: "800px",
    },
  },
}));

interface RecordCapsuleVideoProps {
  classes: any;
  prompt: Prompt;
  handleBack: any;
  handleVideoRecorded: any;
  handleContinue: any;
  handleClearVideo: any;
  handleStartRecording: any;
}

const RecordCapsuleVideo = ({
  prompt,
  handleBack,
  handleContinue,
  handleStartRecording,
  handleClearVideo,
}: RecordCapsuleVideoProps) => {
  const { videoBlob, setVideoBlob } = useRecordedVideo();

  const onBack = () => {
    handleBack();
  };

  const onStartRecording = () => {
    handleStartRecording();
  };

  const onStopReplaying = () => {
    handleClearVideo();
  };

  const onContinue = () => {
    handleContinue(videoBlob);
  };

  const shouldDisableNextButton = () => {
    return videoBlob === null;
  };

  return (
    <Root>
      <div className={classes.videoRecorder}>
        <VideoRecorder
          onRecordingComplete={(videoBlob: Blob) => {
            setVideoBlob(videoBlob);
          }}
          timeLimit={VIDEO_RECORDER_TIME_LIMIT}
          isOnInitially={true}
          onStopReplaying={onStopReplaying}
          onStartRecording={onStartRecording}
          renderDisconnectedView={() => <VideoRecorderDisconnectedView />}
          renderActions={({ ...props }) => (
            <VideoRecorderActions {...props} prompt={prompt} />
          )}
        />
      </div>

      {/* TODO: Display video from blob in pairity with video recorder UI. */}
      {/* {videoBlob && (
        <video
          controls
          width={400}
          src={URL.createObjectURL(videoBlob)}
        ></video>
      )} */}

      <div className={classes.buttons}>
        <Button
          id="back"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onBack}
        >
          Back
        </Button>
        <Button
          id="next"
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={onContinue}
          disabled={shouldDisableNextButton()}
        >
          Next
        </Button>
      </div>
    </Root>
  );
};

// @ts-ignore
export default RecordCapsuleVideo;
