import { Avatar } from "@mui/material";
import api from "../../Api";
import { useEffect, useState } from "react";

interface ProfilePictureProps {
  /**
   * The path to the user's avatar, from the public.users table
   */
  avatarPath?: string;
}

/**
 * Component for displaying the user's profile picture
 */
const ProfilePicture: React.FC<ProfilePictureProps> = (props) => {
  const { avatarPath } = props;
  const [imageUrl, setPictureUrl] = useState("");

  const fetchPictureUrl = async () => {
    if (avatarPath) {
      const avatarUrl = await api.storage.getAvatarUrl(avatarPath);
      setPictureUrl(avatarUrl);
    }
  };

  useEffect(() => {
    fetchPictureUrl();
  }, [avatarPath]);

  return <Avatar src={imageUrl} sx={{ width: "10rem", height: "10rem" }} />;
};

export default ProfilePicture;
