import React from "react";
import { Button, styled } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface BackButtonProps {
  handleBack: () => void;
}

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginLeft: theme.spacing(3),
}));

const BackButton: React.FC<BackButtonProps> = ({ handleBack }) => {
  return (
    <StyledButton
      variant="contained"
      color="primary"
      size="large"
      startIcon={<ArrowBackIcon />}
      onClick={handleBack}
    >
      Back
    </StyledButton>
  );
};

export default BackButton;
