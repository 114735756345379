/**
 * Helper functions for capsules
 */

import { Capsule } from "../Api/capsules";
import { AttachmentType } from "../types/types";

/**
 * Given a capsule, get the path to the capsule's attachment
 * @param capsule The relevant capsule
 * @returns The key to the attachment for this capsule, or null if none was found
 */
export function getCapsuleAttachmentKey(capsule: Capsule): string | null {
  const attachment = capsule.attachments.find(
    (a) => a.type === AttachmentType.ATTACHMENT,
  );
  if (attachment) {
    return attachment.blob.key;
  }

  return null;
}

/**
 * Given a capsule, get the path to the capsule's thumbnail. First checks for the thumbnail image, then falls back to the preview gif
 * @param capsule The relevant capsule
 * @returns The path to the thumbnail for this capsule, or null if none was found
 */
export function getCapsuleThumbnailKey(capsule: Capsule): string | null {
  const { attachments } = capsule;
  const imageThumbnail = attachments.find(
    (a) => a.type === AttachmentType.THUMBNAIL,
  );
  if (imageThumbnail) {
    return imageThumbnail.blob.key;
  }

  // fallback to gif preview if thumbnail does not exist
  const gifPreview = attachments.find((a) => a.type === AttachmentType.PREVIEW);
  if (gifPreview) {
    return gifPreview.blob.key;
  }

  return null;
}
