import { TextField, styled } from "@mui/material";
import strings from "../../../common/strings";
import { FieldErrors, UseFormRegister } from "react-hook-form";

const classes = {
  firstName: "firstName",
};
const NameFieldsRoot = styled("div", {
  name: "NameFields",
  slot: "root",
})(({ theme }) => ({
  padding: theme.spacing(1, 0),
  display: "flex",

  [`.${classes.firstName}`]: {
    marginRight: theme.spacing(1),
  },
}));

interface NameFieldProps {
  /**
   * Register function from useForm, used to link the name inputs to form validation
   */
  register: UseFormRegister<any>;

  /**
   * errors from useForm, used to display any name field errors
   */
  errors: FieldErrors;

  /**
   * Whether the inputs should be read-only or editable
   */
  isReadOnly?: boolean;

  /**
   * Whether the inputs will render with a value
   */
  hasDefaultValue?: boolean;
}

const NameFields: React.FC<NameFieldProps> = (props) => {
  const { register, isReadOnly, errors, hasDefaultValue } = props;

  const firstNameErrorMessage = errors.firstName
    ? (errors.firstName.message as string)
    : "";
  const lastNameErrorMessage = errors.lastName
    ? (errors.lastName.message as string)
    : "";

  return (
    <NameFieldsRoot>
      <TextField
        {...register("firstName")}
        variant="outlined"
        required
        disabled={isReadOnly}
        label={strings.FIRST_NAME}
        fullWidth
        autoComplete="given-name"
        error={!!errors.firstName}
        helperText={firstNameErrorMessage}
        InputLabelProps={{ shrink: hasDefaultValue }}
        className={classes.firstName}
      />
      <TextField
        {...register("lastName")}
        variant="outlined"
        required
        disabled={isReadOnly}
        label={strings.LAST_NAME}
        fullWidth
        autoComplete="family-name"
        error={!!errors.lastName}
        helperText={lastNameErrorMessage}
        InputLabelProps={{ shrink: hasDefaultValue }}
      />
    </NameFieldsRoot>
  );
};

export default NameFields;
